import classNames from "classnames";
import { useFormikContext } from "formik";
import React from "react";
import styles from "./FormInput.module.scss";

interface Props {
  name: string;
  type: string;
  label: string;
  maxLength?: number;
  rows?: number;
  textarea?: boolean;
  special?: boolean;
}

function FormInput(props: Props) {
  const { name, type, label, maxLength, textarea, special, rows } = props;
  const context = useFormikContext<{ [name: string]: any }>();
  const inputProps = {
    type: type,
    name: name,
    placeholder: label,
    onChange: context.handleChange,
    value: context.values[name],
  };
  return (
    <div className={classNames(styles.main, { [styles.special]: special })}>
      {/* <label htmlFor={name}>{label}</label> */}
      {textarea ? (
        <textarea
          {...inputProps}
          rows={rows}
          autoComplete={"off"}
          autoCorrect={"on"}
          autoFocus={false}
          maxLength={maxLength}
        />
      ) : (
        <input {...inputProps} />
      )}
    </div>
  );
}

export default FormInput;
