import { Formik } from "formik"
import { useDropzone } from "react-dropzone"
import "react-dropzone/examples/theme.css"

import { Statement } from "rdflib"
import { useEffect, useMemo, useState } from "react"
import { Image, Music, Plus } from "react-feather"
import { useNavigation } from "react-router"
import AnnotationEditor from "../../components/AnnotationEditor/AnnotationEditor"
import Button from "../../components/Button/Button"
import FormInput from "../../components/FormInput/FormInput"
import MediaGrid from "../../components/MediaGrid/MediaGrid"
import Post from "../../components/Post/Post"
import Page from "../Page"
import styles from "./Uploadpage.module.scss"
import UploadForm from "./components/UploadForm/UploadForm"

export interface CreatePostInput {
  acceptedMedia: { link?: string; file: File }[]
  title?: string
  caption?: string
  annotations?: Statement[]
}

const renderFileIconByType = (type: string) => {
  switch (type) {
    case "image":
      return <Image />
    case "audio":
      return <Music />
  }
}

function UploadPage() {
  const { location } = useNavigation()
  const [formState, setFormState] = useState<
    "select" | "edit" | "annotate" | "upload"
  >("select")
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
    multiple: true,
    maxFiles: 7,
  })
  const [nextPost, setNextPost] = useState<CreatePostInput | undefined>()
  const [acceptedMedia, setAcceptedMedia] = useState<
    { link?: string; file: File }[]
  >(nextPost?.acceptedMedia ?? [])
  // const { isMobile } = useIsMobile();

  const addLink = async (link: string) => {
    if (!acceptedMedia.find((accepted) => accepted.link === link)) {
      let filename = new URL(link).pathname.split("/").pop() as string
      let fetchError = false
      let type: string
      const fileRes = await fetch(link).catch(() => {
        fetchError = true
        return
      })
      if (filename) {
        type = "link"
      }
      if (!fetchError && fileRes) {
        type = fileRes.headers.get("Content-Type") as string
        const file = new File([await fileRes.arrayBuffer()], filename, {
          type,
        })
        setAcceptedMedia([
          ...acceptedMedia,
          {
            link,
            file,
          },
        ])
      }
    }
  }

  const saveForm = async (values: CreatePostInput) => {
    setNextPost(values)
  }

  useEffect(() => {
    if (location?.pathname.startsWith("/upload") && !window.onbeforeunload) {
      window.onbeforeunload = function () {
        return "You have unsaved changes! If you leave this page, your changes will be lost."
      }
    }
    const newlyAdded = acceptedFiles.filter(
      (file) =>
        !acceptedMedia.find(
          (acceptedFile) => acceptedFile.file.name === file.name
        )
    )
    setAcceptedMedia([
      ...acceptedMedia,
      ...newlyAdded.map((file) => ({ file })),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles])

  const thumbnails = useMemo(() => {
    saveForm({ acceptedMedia })
    return acceptedMedia.map((media) => {
      const image = media.file.type.startsWith("image")
        ? URL.createObjectURL(media.file)
        : undefined
      const icon = renderFileIconByType(media.file.type.split("/")[0])
      return { image, icon, file: media.file }
    })
  }, [acceptedMedia])

  const notice = useMemo(() => {
    if (formState === "select") {
      return (
        <aside>
          <p>Select the photos that you want to upload to your pod</p>
        </aside>
      )
    } else if (formState === "edit") {
      return (
        <aside>
          <p>You can add a title or a caption to your post if you want.</p>
        </aside>
      )
    } else if (formState === "annotate") {
      return (
        <aside>
          <p>
            Here you can add any additional metadata that describes your post.
          </p>
        </aside>
      )
    } else if (formState === "upload") {
      return (
        <aside>
          <p>You can decide in which collection you want to store this post.</p>
        </aside>
      )
    }
  }, [formState])

  return (
    <Page title="The Projektor" rightSidebar={notice}>
      <div className={styles.main}>
        {/* <h2 className={styles.header}></h2> */}
        {formState === "select" && (
          <Formik
            initialValues={{ link: "" }}
            onSubmit={({ link }, { resetForm }) => {
              if (link) addLink(link)
              resetForm()
            }}
          >
            {({ handleSubmit, handleChange, dirty }) => {
              if (dirty) {
                window.onbeforeunload = function () {
                  return "You have unsaved changes! If you leave this page, your changes will be lost."
                }
              }
              return (
                <>
                  <form
                    className={styles.formWrapper}
                    onSubmit={handleSubmit}
                    onChange={handleChange}
                  >
                    <div className={styles.form}>
                      <p>Add content by entering a link:</p>
                      <div className={styles.link}>
                        <FormInput name="link" type="text" label="Link" />
                        <Button
                          size="small"
                          color="green"
                          disabled={!dirty}
                          onClick={() => {
                            handleSubmit()
                          }}
                        >
                          <Plus />
                        </Button>
                      </div>
                      <p>Or by uploading:</p>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} id="dropzone" />
                        <p>
                          📁 Drag 'n' drop something here or click to select
                          files
                        </p>
                        <em>(Only jpeg and png images will be accepted)</em>
                      </div>
                      <br></br>
                      {acceptedMedia.length !== 0 && (
                        <MediaGrid
                          media={thumbnails.map((thumbnail) => ({ thumbnail }))}
                          onDelete={(filename) => {
                            const without = acceptedMedia.reduce(
                              (acceptedMedia, current) => {
                                if (current.file.name !== filename) {
                                  return [...acceptedMedia, current]
                                } else {
                                  return acceptedMedia
                                }
                              },
                              [] as { link?: string; file: File }[]
                            )
                            setAcceptedMedia(without)
                          }}
                        />
                      )}
                    </div>
                    <Button
                      color="grey"
                      onClick={() => {
                        saveForm({ acceptedMedia })
                        setFormState("edit")
                      }}
                      disabled={acceptedMedia.length === 0}
                    >
                      Next
                    </Button>
                  </form>
                </>
              )
            }}
          </Formik>
        )}
        {formState === "edit" && (
          <Formik
            initialValues={{ title: "", caption: "" }}
            onSubmit={console.debug}
          >
            {({ values, dirty, handleSubmit, handleChange }) => {
              return (
                <form
                  className={styles.formWrapper}
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                >
                  <div className={styles.form}>
                    <Post
                      title={values.title}
                      caption={values.caption}
                      media={thumbnails}
                      editable
                    />
                  </div>
                  <div className={styles.formButtons}>
                    <Button
                      color="grey"
                      onClick={() => {
                        setFormState("select")
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      color="grey"
                      onClick={() => {
                        if (nextPost?.acceptedMedia)
                          saveForm({
                            acceptedMedia: nextPost.acceptedMedia,
                            title: values.title,
                            caption: values.caption,
                          })
                        setFormState("upload")
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              )
            }}
          </Formik>
        )}
        {formState === "upload" && (
          <UploadForm post={nextPost as CreatePostInput} />
        )}
        {formState === "annotate" && (
          <Formik initialValues={{ annotations: [] }} onSubmit={console.debug}>
            {({ values, dirty, handleSubmit, handleChange }) => {
              return (
                <form
                  className={styles.formWrapper}
                  onSubmit={handleSubmit}
                  onChange={handleChange}
                >
                  <div className={styles.form}>
                    <AnnotationEditor />
                  </div>
                  <div className={styles.formButtons}>
                    <Button
                      color="grey"
                      onClick={() => {
                        setFormState("edit")
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      color="grey"
                      onClick={() => setFormState("annotate")}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              )
            }}
          </Formik>
        )}
      </div>
    </Page>
  )
}

export default UploadPage
