import styles from "./Switch.module.scss";

interface Props {
  label: JSX.Element;
}

function Switch(props: Props) {
  const { label } = props;

  return (
    <div className={styles.main}>
      {label}
      <input type="checkbox" />
    </div>
  );
}

export default Switch;
