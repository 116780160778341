import classNames from "classnames"
import { useCallback, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Button from "../../components/Button/Button"
import EmptyView from "../../components/EmptyView/EmptyView"
import MediaGrid from "../../components/MediaGrid/MediaGrid"
import Post from "../../components/Post/Post"
import { DataDiscoveryContext } from "../../context/DataDiscoveryContext"
import { DisplayPost, useFetchPosts } from "../../hooks/post"

import Page from "../Page"

import styles from "./ArchivePage.module.scss"
import {
  CollectionIndexEntryShape,
  CollectionShape,
  collection,
} from "../../generated/shex"
import { PodConnectorContext } from "react-pod-connector"

function ArchiveSidebar() {
  return (
    <aside>
      <p>
        This is your archive page, where all the media that you have posted will
        appear.
      </p>
    </aside>
  )
}

function ArchivePage() {
  const { loading, myPosts, fetchMyPosts } = useFetchPosts()
  const [focussedPost, setFocussedPost] = useState<DisplayPost | undefined>()
  const [firstImage, setFirstImage] = useState(0)

  const {
    importedData: { posts, collections },
  } = useContext(DataDiscoveryContext)
  const { session } = useContext(PodConnectorContext)

  const [selectedCollectionIndex, setSelectedCollectionIndex] = useState<
    CollectionIndexEntryShape | undefined
  >(undefined)
  const [selectedCollection, setSelectedCollection] = useState<
    CollectionShape | undefined
  >(undefined)

  useEffect(() => {
    if (posts?.length && !myPosts.length) {
      fetchMyPosts()
      setSelectedCollectionIndex(
        collections?.length ? collections[0] : undefined
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMyPosts, myPosts.length, posts?.length])

  useEffect(() => {
    if (session && selectedCollectionIndex) {
      collection.fetcher._fetch = session.fetch
      collection
        .findOne({
          where: { id: selectedCollectionIndex.id },
          doc: selectedCollectionIndex.id,
        })
        .then(({ data }) => {
          if (data) setSelectedCollection(data)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollectionIndex])

  const focusPost = useCallback(
    (post: DisplayPost, i: number) => {
      setFirstImage(i)
      setFocussedPost(post)
    },
    [setFocussedPost]
  )

  return (
    <Page
      title="The Projektor"
      rightSidebar={<ArchiveSidebar />}
      loading={loading}
    >
      {!myPosts?.length ? (
        <EmptyView
          message="It looks like you don't have anything in your archive yet."
          actions={
            <div className={styles.emptyActions}>
              <Link to="/upload">
                <Button color="blue">Create a new post</Button>
              </Link>
            </div>
          }
        />
      ) : (
        <div>
          <div className={styles.collections}>
            {collections?.map((collection) => {
              return (
                <Button
                  color="grey"
                  key={collection.id}
                  onClick={() => {
                    setSelectedCollectionIndex(collection)
                  }}
                >
                  {collection.title}
                </Button>
              )
            })}
          </div>
          <MediaGrid
            media={myPosts.reduce((allMedia, post) => {
              if (!selectedCollection?.holds?.includes(post.id)) {
                return allMedia
              }
              return [
                ...allMedia,
                ...post.media.map((m, i) => ({
                  thumbnail: {
                    image: m.image,
                  },
                  onClick: () => {
                    focusPost(post, i)
                  },
                })),
              ]
            }, [] as { thumbnail: { image: string }; onClick: () => void }[])}
          />
        </div>
      )}
      <div
        className={classNames(styles.post, {
          [styles.focussed]: !!focussedPost,
        })}
      >
        {focussedPost && (
          <Post
            onCancel={() => {
              setFocussedPost(undefined)
            }}
            firstImage={firstImage}
            title={focussedPost?.title as string}
            caption={focussedPost?.caption as string}
            media={focussedPost?.media as { image: string }[]}
            focusOnly
          />
        )}
      </div>
    </Page>
  )
}

export default ArchivePage
