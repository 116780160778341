import { NamedNode, Literal } from "rdflib";

import { Shape } from "shex-methods";

export type NameIndexEntryShape = {
  id: string; // the url of a node of this shape
  inAddressBook: string; // The link to an entry in an Addressbook
  name: string; // The name of a person in an Addressbook
};

export type NameIndexEntryShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  inAddressBook: URL | NamedNode; // The link to an entry in an Addressbook
  name: string | Literal; // The name of a person in an Addressbook
};

export type NameIndexEntryShapeUpdateArgs =
  Partial<NameIndexEntryShapeCreateArgs>;

export type AddressBookShape = {
  id: string; // the url of a node of this shape
  owner: string; // The owner of the Addressbook
  title: string; // The title of the Addressbook
  nameEmailIndex: string; // The link of a name index in the Addressbook
  groupIndex: string; // The link to a group index in the Addressbook
};

export type AddressBookShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  owner: URL | NamedNode; // The owner of the Addressbook
  title: string | Literal; // The title of the Addressbook
  nameEmailIndex: URL | NamedNode; // The link of a name index in the Addressbook
  groupIndex: URL | NamedNode; // The link to a group index in the Addressbook
};

export type AddressBookShapeUpdateArgs = Partial<AddressBookShapeCreateArgs>;

export type AddressBookIndividualEntryShape = {
  id: string; // the url of a node of this shape
  type: AddressBookIndividualEntryShapeType.Individual; // This describes an individual
  url: AddressBookEntryShape; // The url of the Address Book entry
};

export type AddressBookIndividualEntryShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: AddressBookIndividualEntryShapeType.Individual; // This describes an individual
  url: URL | NamedNode | AddressBookEntryShapeCreateArgs; // The url of the Address Book entry
};

export type AddressBookIndividualEntryShapeUpdateArgs =
  Partial<AddressBookIndividualEntryShapeCreateArgs>;

export type AddressBookEntryShape = {
  id: string; // the url of a node of this shape
  type: AddressBookEntryShapeType.WebId; // This Address Book entry contains a WebID
  value: string; // The WebID in this Address Book entry
};

export type AddressBookEntryShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: AddressBookEntryShapeType.WebId; // This Address Book entry contains a WebID
  value: URL | NamedNode; // The WebID in this Address Book entry
};

export type AddressBookEntryShapeUpdateArgs =
  Partial<AddressBookEntryShapeCreateArgs>;

export enum AddressBookIndividualEntryShapeType {
  Individual = "http://www.w3.org/2006/vcard/ns#Individual",
}

export enum AddressBookEntryShapeType {
  WebId = "http://www.w3.org/2006/vcard/ns#WebId",
}

export enum NameIndexEntryShapeContext {
  inAddressBook = "vcard:inAddressBook",
  name = "foaf:name",
}

export enum AddressBookShapeContext {
  owner = "acl:owner",
  title = "purl:title",
  nameEmailIndex = "vcard:nameEmailIndex",
  groupIndex = "vcard:groupIndex",
}

export enum AddressBookIndividualEntryShapeContext {
  type = "rdf:type",
  url = "vcard:url",
}

export enum AddressBookEntryShapeContext {
  type = "rdf:type",
  value = "vcard:value",
}

export const AddressBookShapesShex = `
PREFIX prk: <https://projektor.technology/projektor.ttl#>
PREFIX vcard: <http://www.w3.org/2006/vcard/ns#>
PREFIX foaf: <http://xmlns.com/foaf/0.1/>
PREFIX acl: <http://www.w3.org/ns/auth/acl#>
PREFIX purl: <http://purl.org/dc/elements/1.1/>
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

prk:NameIndexEntryShape {
  vcard:inAddressBook IRI 
    // rdfs:comment  "The link to an entry in an Addressbook" ;
  foaf:name xsd:string 
    // rdfs:comment  "The name of a person in an Addressbook" ;
}
        
prk:AddressBookShape {
  acl:owner IRI
    // rdfs:comment  "The owner of the Addressbook" ;
  purl:title xsd:string
    // rdfs:comment  "The title of the Addressbook" ;
  vcard:nameEmailIndex IRI
    // rdfs:comment  "The link of a name index in the Addressbook" ;
  vcard:groupIndex IRI
    // rdfs:comment  "The link to a group index in the Addressbook" ;
}

prk:AddressBookIndividualEntryShape {
  a [ vcard:Individual ]
    // rdfs:comment  "This describes an individual" ;
  vcard:url @prk:AddressBookEntryShape
    // rdfs:comment  "The url of the Address Book entry" ;
}

prk:AddressBookEntryShape {
  a [ vcard:WebId ]
    // rdfs:comment  "This Address Book entry contains a WebID" ;
  vcard:value IRI 
    // rdfs:comment  "The WebID in this Address Book entry" ;
}
`;

export const nameIndexEntry = new Shape<
  NameIndexEntryShape,
  NameIndexEntryShapeCreateArgs
>({
  id: "https://projektor.technology/projektor.ttl#NameIndexEntryShape",
  shape: AddressBookShapesShex,
  context: NameIndexEntryShapeContext,
});

export const addressBook = new Shape<
  AddressBookShape,
  AddressBookShapeCreateArgs
>({
  id: "https://projektor.technology/projektor.ttl#AddressBookShape",
  shape: AddressBookShapesShex,
  context: AddressBookShapeContext,
});

export const addressBookIndividualEntry = new Shape<
  AddressBookIndividualEntryShape,
  AddressBookIndividualEntryShapeCreateArgs
>({
  id: "https://projektor.technology/projektor.ttl#AddressBookIndividualEntryShape",
  shape: AddressBookShapesShex,
  context: AddressBookIndividualEntryShapeContext,
  type: AddressBookIndividualEntryShapeType,
  childContexts: [AddressBookEntryShapeContext],
});

export const addressBookEntry = new Shape<
  AddressBookEntryShape,
  AddressBookEntryShapeCreateArgs
>({
  id: "https://projektor.technology/projektor.ttl#AddressBookEntryShape",
  shape: AddressBookShapesShex,
  context: AddressBookEntryShapeContext,
  type: AddressBookEntryShapeType,
});

export type PostShape = {
  id: string; // the url of a node of this shape
  type: PostShapeType.Post; // This describes a post
  media?: string | string[]; // The media of a post in an Archive
};

export type PostShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: PostShapeType.Post; // This describes a post
  media?: URL | NamedNode | (URL | NamedNode)[]; // The media of a post in an Archive
};

export type PostShapeUpdateArgs = Partial<PostShapeCreateArgs>;

export type PostIndexEntryShape = {
  id: string; // the url of a node of this shape
  type: PostIndexEntryShapeType.PostIndexEntry; // This describes a post index entry
  inArchive: string; // The link to an entry in an archive
  title?: string; // The title of a post
  caption?: string; // The caption of a post
};

export type PostIndexEntryShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: PostIndexEntryShapeType.PostIndexEntry; // This describes a post index entry
  inArchive: URL | NamedNode; // The link to an entry in an archive
  title?: string | Literal; // The title of a post
  caption?: string | Literal; // The caption of a post
};

export type PostIndexEntryShapeUpdateArgs =
  Partial<PostIndexEntryShapeCreateArgs>;

export type ArchiveShape = {
  id: string; // the url of a node of this shape
  type: ArchiveShapeType.Archive; // This describes a post
  owner: string; // The owner of an archive
  title: string; // The title of an archive
  postIndex: string; // The link to a post index in the archive
  collectionIndex: string; // The link to an index of colelction in the archive
};

export type ArchiveShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: ArchiveShapeType.Archive; // This describes a post
  owner: URL | NamedNode; // The owner of an archive
  title: string | Literal; // The title of an archive
  postIndex: URL | NamedNode; // The link to a post index in the archive
  collectionIndex: URL | NamedNode; // The link to an index of colelction in the archive
};

export type ArchiveShapeUpdateArgs = Partial<ArchiveShapeCreateArgs>;

export type CollectionShape = {
  id: string; // the url of a node of this shape
  type: CollectionShapeType.Collection; // This describes a collection
  holds?: string | string[]; // The url of the posts in this collection
};

export type CollectionShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: CollectionShapeType.Collection; // This describes a collection
  holds?: URL | NamedNode | (URL | NamedNode)[]; // The url of the posts in this collection
};

export type CollectionShapeUpdateArgs = Partial<CollectionShapeCreateArgs>;

export type CollectionIndexEntryShape = {
  id: string; // the url of a node of this shape
  type: CollectionIndexEntryShapeType.CollectionIndexEntry; // This describes a collection
  title: string; // The title of a collection
  inArchive: string; // The link to an entry in an archive
};

export type CollectionIndexEntryShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: CollectionIndexEntryShapeType.CollectionIndexEntry; // This describes a collection
  title: string | Literal; // The title of a collection
  inArchive: URL | NamedNode; // The link to an entry in an archive
};

export type CollectionIndexEntryShapeUpdateArgs =
  Partial<CollectionIndexEntryShapeCreateArgs>;

export enum PostShapeType {
  Post = "https://projektor.technology/projektor.ttl#Post",
}

export enum PostIndexEntryShapeType {
  PostIndexEntry = "https://projektor.technology/projektor.ttl#PostIndexEntry",
}

export enum ArchiveShapeType {
  Archive = "https://projektor.technology/projektor.ttl#Archive",
}

export enum CollectionShapeType {
  Collection = "https://projektor.technology/projektor.ttl#Collection",
}

export enum CollectionIndexEntryShapeType {
  CollectionIndexEntry = "https://projektor.technology/projektor.ttl#CollectionIndexEntry",
}

export enum PostShapeContext {
  type = "rdf:type",
  media = "prk:media",
}

export enum PostIndexEntryShapeContext {
  type = "rdf:type",
  inArchive = "prk:inArchive",
  title = "prk:title",
  caption = "prk:caption",
}

export enum ArchiveShapeContext {
  type = "rdf:type",
  owner = "acl:owner",
  title = "purl:title",
  postIndex = "prk:postIndex",
  collectionIndex = "prk:collectionIndex",
}

export enum CollectionShapeContext {
  type = "rdf:type",
  holds = "prk:holds",
}

export enum CollectionIndexEntryShapeContext {
  type = "rdf:type",
  title = "purl:title",
  inArchive = "prk:inArchive",
}

export const ArchiveShapesShex = `
PREFIX prk: <https://projektor.technology/projektor.ttl#>
PREFIX vcard: <http://www.w3.org/2006/vcard/ns#>
PREFIX foaf: <http://xmlns.com/foaf/0.1/>
PREFIX acl: <http://www.w3.org/ns/auth/acl#>
PREFIX purl: <http://purl.org/dc/elements/1.1/>
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

prk:PostShape {
  a [ prk:Post ]
    // rdfs:comment  "This describes a post" ;
  prk:media IRI *
    // rdfs:comment  "The media of a post in an Archive" ;
}

prk:PostIndexEntryShape {
  a [ prk:PostIndexEntry ]
    // rdfs:comment  "This describes a post index entry" ;
  prk:inArchive IRI 
    // rdfs:comment  "The link to an entry in an archive" ;
  prk:title xsd:string ?
    // rdfs:comment  "The title of a post" ;
  prk:caption xsd:string ? 
    // rdfs:comment  "The caption of a post" ;
}
        
prk:ArchiveShape {
  a [ prk:Archive ]
    // rdfs:comment  "This describes a post" ;
  acl:owner IRI
    // rdfs:comment  "The owner of an archive" ;
  purl:title xsd:string
    // rdfs:comment  "The title of an archive" ;
  prk:postIndex IRI
    // rdfs:comment  "The link to a post index in the archive" ;
  prk:collectionIndex IRI
    // rdfs:comment  "The link to an index of colelction in the archive" ;
}

prk:CollectionShape {
  a [ prk:Collection ]
    // rdfs:comment  "This describes a collection" ;
  prk:holds IRI *
    // rdfs:comment  "The url of the posts in this collection" ;
}

prk:CollectionIndexEntryShape {
  a [ prk:CollectionIndexEntry ]
    // rdfs:comment  "This describes a collection" ;
  purl:title xsd:string
    // rdfs:comment  "The title of a collection" ;
  prk:inArchive IRI 
    // rdfs:comment  "The link to an entry in an archive" ;
}
`;

export const post = new Shape<PostShape, PostShapeCreateArgs>({
  id: "https://projektor.technology/projektor.ttl#PostShape",
  shape: ArchiveShapesShex,
  context: PostShapeContext,
  type: PostShapeType,
});

export const postIndexEntry = new Shape<
  PostIndexEntryShape,
  PostIndexEntryShapeCreateArgs
>({
  id: "https://projektor.technology/projektor.ttl#PostIndexEntryShape",
  shape: ArchiveShapesShex,
  context: PostIndexEntryShapeContext,
  type: PostIndexEntryShapeType,
});

export const archive = new Shape<ArchiveShape, ArchiveShapeCreateArgs>({
  id: "https://projektor.technology/projektor.ttl#ArchiveShape",
  shape: ArchiveShapesShex,
  context: ArchiveShapeContext,
  type: ArchiveShapeType,
});

export const collection = new Shape<CollectionShape, CollectionShapeCreateArgs>(
  {
    id: "https://projektor.technology/projektor.ttl#CollectionShape",
    shape: ArchiveShapesShex,
    context: CollectionShapeContext,
    type: CollectionShapeType,
  }
);

export const collectionIndexEntry = new Shape<
  CollectionIndexEntryShape,
  CollectionIndexEntryShapeCreateArgs
>({
  id: "https://projektor.technology/projektor.ttl#CollectionIndexEntryShape",
  shape: ArchiveShapesShex,
  context: CollectionIndexEntryShapeContext,
  type: CollectionIndexEntryShapeType,
});

export type PersonShape = {
  id: string; // the url of a node of this shape
  type: PersonShapeType.Person; // This shape describes a person
  name?: string | string[]; // The name of a person
};

export type PersonShapeCreateArgs = {
  id?: string | NamedNode; // the url to match or create the node with e.g. 'https://example.com#this', 'https://example.com/profile/card#me'
  type: PersonShapeType.Person; // This shape describes a person
  name?: string | Literal | (string | Literal)[]; // The name of a person
};

export type PersonShapeUpdateArgs = Partial<PersonShapeCreateArgs>;

export enum PersonShapeType {
  Person = "http://schema.org/Person",
}

export enum PersonShapeContext {
  type = "rdf:type",
  name = "foaf:name",
}

export const PersonShapesShex = `
PREFIX prk: <https://projektor.technology/projektor.ttl#>
PREFIX foaf: <http://xmlns.com/foaf/0.1/>
PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#> 
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
PREFIX schema: <http://schema.org/>

prk:PersonShape {
  a [ schema:Person ]
    // rdfs:comment  "This shape describes a person" ;
  foaf:name xsd:string *
    // rdfs:comment  "The name of a person" ;
}
`;

export const person = new Shape<PersonShape, PersonShapeCreateArgs>({
  id: "https://projektor.technology/projektor.ttl#PersonShape",
  shape: PersonShapesShex,
  context: PersonShapeContext,
  type: PersonShapeType,
});
