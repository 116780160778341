import { useContext, useMemo } from "react"
import { Link, useParams } from "react-router-dom"
import { DataDiscoveryContext } from "../../context/DataDiscoveryContext"
import { getUsernameFromWebId } from "../../helpers/username"
import { usePersonProfile } from "../../hooks/profile"

import Page from "../Page"

import { PodConnectorContext } from "react-pod-connector"
import styles from "./AccountPage.module.scss"

function AccountSidebar() {
  return (
    <aside>
      <p>
        This is your profile, the same way it is displayed to other users of
        Projektor.
      </p>
      <p>
        You can add media to your profile by publishing something in your
        archive.
      </p>
    </aside>
  )
}

function AccountPage() {
  const params = useParams()
  const { session } = useContext(PodConnectorContext)
  const {
    privateProfile,
    data: profile,
    loading,
  } = usePersonProfile(params.webId ?? (session?.info.webId as string))
  const { importedData } = useContext(DataDiscoveryContext)

  const name = useMemo(() => {
    return (
      (Array.isArray(profile?.name) ? profile?.name[0] : profile?.name) ??
      getUsernameFromWebId(params.webId ?? (session?.info.webId as string))
    )
  }, [params.webId, profile?.name, session?.info.webId])

  return (
    <Page
      title="The Projektor"
      rightSidebar={<AccountSidebar />}
      loading={loading}
    >
      <div className={styles.main}>
        <h2 className={styles.name}>{name}</h2>
        {privateProfile ? (
          <h3 style={{ color: "lightgrey" }}>
            This users profile is not visible to you.
          </h3>
        ) : (
          <div className={styles.links}>
            {importedData.contacts?.length ? (
              <Link to="/contacts" className={styles.content}>
                <b>{importedData.contacts?.length}</b>
                <b>
                  {importedData.contacts?.length > 1 ? "Contacts" : "Contact"}
                </b>
              </Link>
            ) : null}
            {importedData.posts?.length ? (
              <Link to="/archive" className={styles.content}>
                <b>{importedData.posts?.length}</b>
                <b>{importedData.posts?.length > 1 ? "Posts" : "Post"}</b>
              </Link>
            ) : null}
            {importedData.collections?.length ? (
              <Link to="/archive" className={styles.content}>
                <b>{importedData.collections?.length}</b>
                <b>
                  {importedData.collections?.length > 1
                    ? "Collections"
                    : "Collection"}
                </b>
              </Link>
            ) : null}
          </div>
        )}
      </div>
    </Page>
  )
}

export default AccountPage
