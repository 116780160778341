import { getProfileAll } from "@inrupt/solid-client"
import { Session } from "@inrupt/solid-client-authn-browser"
import { NamedNode } from "rdflib"
import { useContext, useEffect, useMemo, useState } from "react"
import { PodConnectorContext } from "react-pod-connector"
import DataPolicyPrompt, {
  DataPrompt,
} from "./components/DataPolicyPrompt/DataPolicyPrompt"
import Modal from "./components/Modal/Modal"
import {
  DataDiscoveryContext,
  ImportedData,
} from "./context/DataDiscoveryContext"
import { dataPrompts } from "./dataPrompts"
import {
  AddressBookIndividualEntryShape,
  CollectionIndexEntryShapeType,
  CollectionShapeType,
  addressBookIndividualEntry,
  collection,
  collectionIndexEntry,
  nameIndexEntry,
  postIndexEntry,
} from "./generated/shex"
import { getNewIndexEntryPathFor } from "./hooks/post"

interface Props {
  children: JSX.Element | JSX.Element[]
}

export async function getProfileAndStorageAdresses(session: Session, webId: string) {
  const storages = [] as string[]
  const profiles = [] as string[]
  if (!webId) {
    return [storages, profiles]
  }
  const profile = (
    (
      await getProfileAll(webId, {
        fetch: session.fetch,
      })
    ).webIdProfile.graphs.default as Record<string, any>
  )[webId]
  storages.push(
    ...profile.predicates["http://www.w3.org/ns/pim/space#storage"].namedNodes
  )
  try {
    const profileAddress =
      profile.predicates["http://xmlns.com/foaf/0.1/isPrimaryTopicOf"]
        .namedNodes
    profiles.push(...profileAddress)
  } catch {
    const profileAddress = profile.url
    profiles.push(...profileAddress)
  }

  return [storages, profiles]
}

function DataDiscoveryWrapper(props: Props) {
  const { children } = props
  const { session } = useContext(PodConnectorContext)
  const [discoveredData, setDiscoveredData] = useState(
    Boolean(localStorage.getItem("discoveredData"))
  )
  const [loadingBareData, setLoadingBareData] = useState(false)
  const [discoveringData, setDiscoveringData] = useState(false)
  const [selectedStorage, setSelectedStorage] = useState<string | undefined>()
  const [activeStorages, setActiveStorages] = useState<string[] | undefined>()
  const [activeProfiles, setActiveProfiles] = useState<string[] | undefined>()
  const [importedData, setImportedData] = useState<ImportedData>({
    addressBook: undefined,
    contacts: [],
    archive: undefined,
    posts: [],
    collections: [],
    profile: undefined,
  })

  useEffect(() => {
    if (session?.info.isLoggedIn && session) {
      setLoadingBareData(true)
      getProfileAndStorageAdresses(session as unknown as Session, session.info.webId as string).then(
        ([storages, profiles]) => {
          setActiveStorages(storages)
          setActiveProfiles(profiles)
          setLoadingBareData(false)
          setDiscoveringData(true)
        }
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.info.isLoggedIn])

  const overlay = useMemo(() => {
    if (loadingBareData) {
      return (
        <div
          style={{
            height: "fit-content",
            width: "fit-content",
            margin: "auto",
            marginTop: "20%",
          }}
        >
          <h2>Loading Storage and Profile Locations...</h2>
        </div>
      )
    } else if (
      session?.info.isLoggedIn &&
      (!activeStorages?.length || !activeProfiles?.length)
    ) {
      return (
        <div
          style={{
            height: "fit-content",
            width: "fit-content",
            margin: "auto",
            marginTop: "20%",
          }}
        >
          <h2>
            Failed to load a storage and profile location for your account...
          </h2>
        </div>
      )
    }

    if (discoveringData && session?.fetch) {
      const webId = session?.info.webId as string
      return (
        <DataDiscoveryContext.Provider
          value={{
            discoveringData,
            storageAddresses: activeStorages,
            profileAddresses: activeProfiles,
            importedData,
          }}
        >
          <Modal visible={discoveringData}>
            <DataPolicyPrompt
              hidden={discoveredData}
              onFinish={(storage) => {
                setSelectedStorage(storage)
                setDiscoveringData(false)
                console.debug("Finished")
                localStorage.setItem("discoveredData", "true")
              }}
              onFail={() => {
                setDiscoveredData(false)
                localStorage.removeItem("discoveredData")
              }}
            >
              {(storageAddress) => [
                <DataPrompt
                  {...dataPrompts(storageAddress, webId).addressBook}
                  onExist={(addressBook) => {
                    nameIndexEntry.fetcher._fetch = session.fetch
                    addressBookIndividualEntry.fetcher._fetch = session.fetch
                    nameIndexEntry
                      .findAll({ doc: addressBook.nameEmailIndex })
                      .then((res) => {
                        Promise.all(
                          (res.data ?? []).map((n) => {
                            return addressBookIndividualEntry
                              .findOne({
                                where: { id: n.id },
                                doc: n.id,
                              })
                              .then(({ data }) => {
                                return data
                              })
                          })
                        ).then((addresses) => {
                          const contacts = addresses.filter((a) =>
                            Boolean(a)
                          ) as AddressBookIndividualEntryShape[]
                          setImportedData((imports) => ({
                            ...imports,
                            contacts,
                            addressBook,
                          }))
                        })
                      })
                  }}
                />,
                <DataPrompt
                  {...dataPrompts(storageAddress, webId).profile}
                  onExist={(profile) => {
                    setImportedData((imports) => ({ ...imports, profile }))
                  }}
                />,
                <DataPrompt
                  {...dataPrompts(storageAddress, webId).archive}
                  onExist={async (archive) => {
                    postIndexEntry.fetcher._fetch = session.fetch
                    collectionIndexEntry.fetcher._fetch = session.fetch

                    const posts = await postIndexEntry
                      .findAll({ doc: archive.postIndex })
                      .then(({ data: posts }) => {
                        if (posts) return posts
                        else return []
                      })

                    const collections = await collectionIndexEntry
                      .findAll({ doc: archive.collectionIndex })
                      .then(({ data: collections }) => {
                        if (collections) return collections
                        else return []
                      })

                    if (!collections.length) {
                      const id = getNewIndexEntryPathFor(
                        "Collection",
                        storageAddress,
                        archive.id
                      )
                      collection.fetcher._fetch = session.fetch
                      const defaultCollection = await collection.create({
                        data: {
                          id,
                          type: CollectionShapeType.Collection,
                        },
                        doc: new NamedNode(id).doc().uri,
                      })
                      if (defaultCollection.data) {
                        const defaultCollectionIndex =
                          await collectionIndexEntry.create({
                            data: {
                              id: defaultCollection.data?.id,
                              type: CollectionIndexEntryShapeType.CollectionIndexEntry,
                              inArchive: new URL(archive.id),
                              title: "Main Collection",
                            },
                            doc: archive.collectionIndex,
                          })
                        if (defaultCollectionIndex.data)
                          collections.push(defaultCollectionIndex.data)
                      }
                      // await collectionIndexEntry.create({
                      //   data: { id: archive },
                      // });
                    }

                    setImportedData((imports) => ({
                      ...imports,
                      archive,
                      posts,
                      collections,
                    }))
                  }}
                />,
              ]}
            </DataPolicyPrompt>
          </Modal>
        </DataDiscoveryContext.Provider>
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBareData, discoveringData, importedData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showPrompt = useMemo(() => overlay && !discoveredData, [discoveredData])

  if (showPrompt && overlay) {
    return overlay
  }

  return (
    <DataDiscoveryContext.Provider
      value={{
        discoveringData,
        selectedStorage: selectedStorage,
        storageAddresses: activeStorages,
        profileAddresses: activeProfiles,
        importedData,
        setImportedData,
      }}
    >
      {children}
      {discoveringData && discoveredData && (
        <div style={{ visibility: "hidden" }}>{overlay}</div>
      )}
    </DataDiscoveryContext.Provider>
  )
}

export default DataDiscoveryWrapper
