import pixelArt from "../../logo-art.png"
import styles from "./EmptyView.module.scss"

interface Props {
  message: string
  actions?: JSX.Element
}

function EmptyView(props: Props) {
  const { message, actions } = props

  return (
    <div className={styles.main}>
      <div className={styles.message}>
        <img src={pixelArt} alt="logo" />
        <p>{message}</p>
      </div>
      <div className={styles.actions}>{actions}</div>
    </div>
  )
}

export default EmptyView
