import {
  addressBook,
  archive,
  ArchiveShapeType,
  person,
  PersonShapeType,
} from "./generated/shex";

export const dataPrompts = (storageAddress: string, webId: string) => ({
  addressBook: {
    key: "address-book",
    name: "Address Book",
    path: "/contacts/index.ttl",
    node: new URL("./contacts/index.ttl#this", storageAddress).href,
    storage: new URL(storageAddress as string),
    access: { read: true, write: true },
    shape: addressBook,
    args: {
      owner: new URL(webId as string),
      title: "Contacts",
      nameEmailIndex: new URL("./contacts/people.ttl", storageAddress),
      groupIndex: new URL("./contacts/groups.ttl", storageAddress),
    },
    additionalFiles: [
      {
        url: new URL("./contacts/people.ttl", storageAddress),
        access: { read: true, write: true },
      },
      {
        url: new URL("./contacts/groups.ttl", storageAddress),
        access: { read: true, write: true },
      },
    ],
  },
  profile: {
    key: "profile",
    name: "Profile",
    path: "/extendedProfile",
    node: webId as string,
    storage: new URL(storageAddress as string),
    access: { read: true, write: true },
    shape: person,
    args: { type: PersonShapeType.Person, name: webId.startsWith("https://id.inrupt.com") ? webId.replace("https://id.inrupt.com/", "") : new URL(webId).host.split(".").pop() },
  },
  archive: {
    key: "archive",
    name: "Archive",
    path: "/archive/index.ttl",
    node: new URL("./archive/index.ttl#this", storageAddress).href,
    storage: new URL(storageAddress as string),
    access: { read: true, write: true },
    shape: archive,
    args: {
      title: "Archive",
      type: ArchiveShapeType.Archive,
      owner: new URL(webId as string),
      postIndex: new URL("./archive/posts.ttl", storageAddress),
      collectionIndex: new URL("./archive/collections.ttl", storageAddress),
    },
    additionalFiles: [
      {
        url: new URL("./archive/posts.ttl", storageAddress),
        access: { read: true, write: true },
      },
      {
        url: new URL("./archive/collections.ttl", storageAddress),
        access: { read: true, write: true },
      },
    ],
  },
});
