import React from "react"
import ReactDOM from "react-dom/client"
import { PodConnector } from "react-pod-connector"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import "react-toastify/dist/ReactToastify.css"

import DataDiscoveryWrapper from "./DataDiscoveryWrapper"
import "./index.css"
import logoArt from "./logo-art.png"
import AccountPage from "./pages/AccountPage/AccountPage"
import ArchivePage from "./pages/ArchivePage/ArchivePage"
import ContactsPage from "./pages/ContactsPage/ContactsPage"
import ErrorPage from "./pages/Errorpage/ErrorPage"
import HomePage from "./pages/HomePage/HomePage"
import PreferencesPage from "./pages/PreferencesPage/PreferencesPage"
import SearchPage from "./pages/SearchPage/SearchPage"
import UploadPage from "./pages/UploadPage/UploadPage"
import reportWebVitals from "./reportWebVitals"

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <ErrorPage />,
    hasErrorBoundary: false,
  },
  { path: "/upload", element: <UploadPage /> },
  { path: "/account", element: <AccountPage /> },
  { path: "/account/:webId", element: <AccountPage /> },
  { path: "/archive", element: <ArchivePage /> },
  { path: "/preferences", element: <PreferencesPage /> },
  { path: "/contacts", element: <ContactsPage /> },
  { path: "/contacts/add", element: <ContactsPage /> },
  // { path: "/login", element: <LoginPage /> },
  { path: "/search", element: <SearchPage /> },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <PodConnector
      logo={<img src={logoArt} alt={"logo"} />}
      name="Projektor"
      loginOptions={{
        clientId:
          process.env.NODE_ENV === "development"
            ? undefined
            : "https://projektor.technology/projektor.jsonld",
      }}
      >
      <DataDiscoveryWrapper>
        <RouterProvider router={router} />
      </DataDiscoveryWrapper>
    </PodConnector>
    <ToastContainer theme="colored" hideProgressBar />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
