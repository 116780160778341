import classNames from "classnames";
import styles from "./Card.module.scss";

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

function Card(props: Props) {
  const { children, className } = props;

  return <div className={classNames(styles.main, className)}>{children}</div>;
}

export default Card;
