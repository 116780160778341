import React from "react"
import MediaThumbnail, {
  IMediaAction,
  IMediaThumbnail,
} from "../MediaThumbnail/MediaThumbnail"
import styles from "./MediaGrid.module.scss"
import { v4 as uuid } from "uuid"

interface Props {
  media: { onClick?: () => void; thumbnail: IMediaThumbnail }[]
}

function MediaGrid(props: Props & IMediaAction) {
  const { media, ...rest } = props

  return (
    <div className={styles.main}>
      {media.map(({thumbnail, ...actions}) => {
        return (
          <MediaThumbnail
            {...thumbnail}
            {...actions}
            {...rest}
            key={thumbnail.file?.name ?? uuid()}
          />
        )
      })}
    </div>
  )
}

export default MediaGrid
