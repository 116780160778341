import React from "react"
import { Trash } from "react-feather"
import styles from "./MediaThumbnail.module.scss"

export interface IMediaThumbnail {
  image?: string
  icon?: JSX.Element
  file?: File
}

export interface IMediaAction {
  onClick?: () => void;
  onDelete?: (filename: string) => void
}

function MediaThumbnail(props: IMediaThumbnail & IMediaAction) {
  const { image, icon, file, onDelete, onClick } = props

  const imageBackground = {
    background: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  }

  return (
    <div className={styles.main} onClick={onClick}>
      {image && <div className={styles.image} style={imageBackground} />}
      {!image && icon}
      {onDelete && (
        <div className={styles.tools}>
          <Trash
            style={{ color: "red" }}
            onClick={() => {
              if (file) onDelete(file.name)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default MediaThumbnail
