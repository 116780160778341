import { useState } from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(document.body.clientWidth < 812);
  window.addEventListener("resize", (e) => {
    if (e.currentTarget) {
      setIsMobile(document.body.clientWidth < 812);
    }
  });
  return { isMobile };
};
