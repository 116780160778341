import { Plus } from "react-feather";

import Button from "../Button/Button";
import styles from "./AnnotationEditor.module.scss";

function AnnotationEditor() {
  return (
    <div className={styles.main}>
      <div className={styles.vocabs}>
        Using ... vocabularies{" "}
        <Button color="green" size="small">
          <Plus />
        </Button>
      </div>
      <div className={styles.editor}>
        <div className={styles.headers}>
          <div className={styles.predicates}>Property</div>
          <div className={styles.objects}>Value</div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.add}>
            <Button color="grey">
              <Plus />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnnotationEditor;
