import React, { Dispatch, SetStateAction } from "react"
import {
  AddressBookIndividualEntryShape,
  AddressBookShape,
  ArchiveShape,
  CollectionIndexEntryShape,
  PersonShape,
  PostIndexEntryShape,
} from "../generated/shex"

export interface ImportedData {
  addressBook?: AddressBookShape
  contacts?: AddressBookIndividualEntryShape[]
  archive?: ArchiveShape
  posts?: PostIndexEntryShape[]
  collections?: CollectionIndexEntryShape[]
  profile?: PersonShape
}

export interface IDataDiscoveryContext {
  discoveringData: boolean
  storageAddresses?: string[]
  selectedStorage?: string
  profileAddresses?: string[]
  importedData: ImportedData
  setImportedData?: Dispatch<SetStateAction<ImportedData>>
}

export const DataDiscoveryContext = React.createContext<IDataDiscoveryContext>({
  discoveringData: false,
  importedData: {},
})
