import classNames from "classnames"
import { useContext, useEffect } from "react"
import { Archive, Plus, Search, Settings, User, Users } from "react-feather"
import Helmet from "react-helmet"
import { PodConnectorContext } from "react-pod-connector"
import { useLocation, useNavigate, useNavigation } from "react-router"
import { Link } from "react-router-dom"

import Button from "../components/Button/Button"

import Card from "../components/Card/Card"
import LoginIndicator from "../components/LoginIndicator/LoginIndicator"
import NavigationSideBar from "../components/NavigationSideBar/NavigationSideBar"
import { DataDiscoveryContext } from "../context/DataDiscoveryContext"
import { useIsMobile } from "../hooks/isMobile"
import styles from "./Page.module.scss"

interface Props {
  title: string
  children: JSX.Element | JSX.Element[]
  rightSidebar?: JSX.Element | JSX.Element[]
  loading?: boolean
  maxWidth?: boolean
}

function Page(props: Props) {
  const podContext = useContext(PodConnectorContext)
  const { session, connect } = podContext ?? ({} as PodConnectorContext)
  const { isLoggedIn } = session?.info ?? {}
  const { discoveringData } = useContext(DataDiscoveryContext)
  const location = useLocation()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const { isMobile } = useIsMobile()
  const { title, children, rightSidebar, maxWidth, loading } = props

  useEffect(() => {
    if (!isLoggedIn && navigation.state !== "loading") {
      connect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, location])

  return (
    <div className={styles.columns}>
      <div
        className={classNames(styles.leftSidebar, {
          [styles.hidden]: !isLoggedIn,
        })}
      >
        <Card>
          <NavigationSideBar />
          <LoginIndicator />
        </Card>
      </div>
      <div className={styles.main}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <nav>
          <ul className={styles.pages}>
            {isLoggedIn && (
              <li>
                <Link to="/">
                  <Button color="grey" size="large">
                    <img src={"/favicon.ico"} alt="logo" />
                    Projektor
                  </Button>
                </Link>
              </li>
            )}
            {isLoggedIn && (
              <li>
                {!isMobile && (
                  <input
                    defaultValue={
                      new URLSearchParams(location.search).get("q") as string
                    }
                    autoFocus={location.pathname.startsWith("/search")}
                    type="text"
                    placeholder="Archive Search"
                    className={styles.search}
                    onChange={(e) => {
                      navigate(`/search?q=${e.target.value}`)
                    }}
                  />
                )}
              </li>
            )}
            {isLoggedIn ? (
              isMobile ? (
                <Link to="/account">
                  <Button color="grey" size="large">
                    <User />
                  </Button>
                </Link>
              ) : (
                <li
                  className={classNames({
                    [styles.hidden]: !isLoggedIn,
                  })}
                >
                  <Link to="/upload">
                    <Button color="blue">
                      <Plus />
                    </Button>
                  </Link>
                </li>
              )
            ) : null}
          </ul>
        </nav>
        {isLoggedIn && isMobile && (
          <div className={styles.bottomButtons}>
            <Link
              to="/archive"
              className={classNames({
                [styles.active]: location.pathname.startsWith("/archive"),
              })}
            >
              <Archive />
            </Link>
            <Link
              to="/search"
              className={classNames({
                [styles.active]: location.pathname.startsWith("/search"),
              })}
            >
              <Search />
            </Link>
            <Link
              to="/upload"
              className={classNames({
                [styles.active]: location.pathname.startsWith("/upload"),
              })}
            >
              <Button color="blue">
                <Plus />
              </Button>
            </Link>
            <Link
              to="/contacts"
              className={classNames({
                [styles.active]: location.pathname.startsWith("/contacts"),
              })}
            >
              <Users />
            </Link>
            <Link
              to="/preferences"
              className={classNames({
                [styles.active]: location.pathname.startsWith("/preferences"),
              })}
            >
              <Settings />
            </Link>
          </div>
        )}
        <div
          className={classNames(styles.container, {
            [styles.loading]:
              navigation.state === "loading" || loading || discoveringData,
            [styles.maxWidth]: maxWidth && !(loading || discoveringData),
          })}
        >
          {loading || discoveringData ? (
            <progress className={styles.progress} />
          ) : (
            children
          )}
        </div>
      </div>
      <div
        className={classNames(styles.rightSidebar, {
          [styles.hidden]: !isLoggedIn,
        })}
      >
        <Card>{rightSidebar as JSX.Element}</Card>
      </div>
    </div>
  )
}

export default Page
