import classNames from "classnames"
import { useCallback, useEffect, useMemo, useState } from "react"
import ReactLoading from "react-loading"
import { useLocation } from "react-router"
import MediaGrid from "../../components/MediaGrid/MediaGrid"
import Post from "../../components/Post/Post"
import { DisplayPost, useFetchPosts } from "../../hooks/post"

import Page from "../Page"

import styles from "./SearchPage.module.scss"

function SearchPage() {
  const location = useLocation()

  const query = useMemo(
    () => new URLSearchParams(location.search).get("q") ?? "",
    [location.search]
  )
  const { loading, myPosts, fetchMyPosts } = useFetchPosts()
  const [focussedPost, setFocussedPost] = useState<DisplayPost | undefined>()
  const [firstImage, setFirstImage] = useState(0)

  useEffect(() => {
    fetchMyPosts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [postsFound, setPostsFound] = useState<DisplayPost[]>([])
  const [searching, setSearching] = useState(false)
  const [searchTimeout, setSearchTimeout] = useState<
    ReturnType<typeof setTimeout> | undefined
  >()

  const focusPost = useCallback(
    (post: DisplayPost, i: number) => {
      setFirstImage(i)
      setFocussedPost(post)
    },
    [setFocussedPost]
  )

  useEffect(() => {
    setSearching(true)
    const search = (query: string) => {
      setPostsFound(
        myPosts.reduce((found, p) => {
          if (
            p.title?.toLowerCase().includes(query.toLowerCase()) ||
            p.caption?.toLowerCase().includes(query.toLowerCase())
          ) {
            return [...found, p]
          } else if (
            p.media.find((m) =>
              m.url.toLowerCase().includes(query.toLowerCase())
            )
          ) {
            return [
              ...found,
              {
                ...p,
                media: p.media.filter((m) =>
                  m.url.toLowerCase().includes(query.toLowerCase())
                ),
              },
            ]
          }
          return found
        }, [] as DisplayPost[])
      )
      setSearching(false)
    }

    if (searchTimeout) clearTimeout(searchTimeout)
    setSearchTimeout(setTimeout(() => search(query as string), 1000))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, myPosts.length])

  return (
    <Page title={query ? `Search for ${query}` : "Archive Search"} loading={loading}>
      {searching && query ? (
        <div className={styles.main}>
          <div className={styles.searching}>
            <h2>Searching for "{query}"</h2>
            <ReactLoading type="balls" height={40} width={60} />
          </div>
        </div>
      ) : (
        <div className={styles.main}>
          {query && <h2>Results for "{query}":</h2>}
          <MediaGrid
            media={postsFound.reduce(
              (allMedia, post) => [
                ...allMedia,
                ...post.media.map((m, i) => ({
                  thumbnail: {
                    image: m.image,
                  },
                  onClick: () => {
                    focusPost(post, i)
                  },
                })),
              ],
              [] as { thumbnail: { image: string }; onClick: () => void }[]
            )}
          />
          <div
            className={classNames(styles.post, {
              [styles.focussed]: !!focussedPost,
            })}
          >
            {focussedPost && (
              <Post
                onCancel={() => {
                  setFocussedPost(undefined)
                }}
                firstImage={firstImage}
                title={focussedPost?.title as string}
                caption={focussedPost?.caption as string}
                media={focussedPost?.media as { image: string }[]}
                focusOnly
              />
            )}
          </div>
        </div>
      )}
    </Page>
  )
}

export default SearchPage
