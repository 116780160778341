import { useFormikContext } from "formik";
import styles from "./SelectMenu.module.scss";

interface Props {
  options: { label: string; value: string }[];
  name: string;
  multiple?: boolean;
  defaultValue?: string;
}

function SelectMenu(props: Props) {
  const { options, name, multiple, defaultValue } = props;
  const { setFieldValue } = useFormikContext<Record<string, string>>();

  return (
    <select
      multiple={multiple}
      onSelect={(o) => setFieldValue(name, o)}
      className={styles.main}
      defaultValue={defaultValue}
      onChange={(e) => {
        setFieldValue("name", e.target.value);
      }}
    >
      {options.map((o) => {
        return (
          <option value={o.value} key={o.value}>
            {o.label}
          </option>
        );
      })}
    </select>
  );
}

export default SelectMenu;
