import classnames from "classnames";
import {
  Archive,
  Home,
  PlusSquare,
  User,
  Users
} from "react-feather";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styles from "./NavigationSideBar.module.scss";

function NavigationSideBar() {
  const location = useLocation();

  const navigationOptions = [
    { icon: <Home />, label: "Main", route: "/" },
    { icon: <User />, label: "Account", route: "/account" },
    { icon: <Archive />, label: "Archive", route: "/archive" },
    // { icon: <Send />, label: "Messages", route: "/inbox" },
    { icon: <Users />, label: "Contacts", route: "/contacts" },
    // { icon: <Settings />, label: "Preferences", route: "/preferences" },
    { icon: <PlusSquare />, label: "Add", route: "/upload" },
  ];

  return (
    <div className={styles.main}>
      <ul>
        {navigationOptions.map((option) => {
          return (
            <Link
              to={option.route}
              className={styles.optionWrapper}
              key={option.label}
            >
              <li
                className={classnames(styles.option, {
                  [styles.active]:
                    option.route === "/"
                      ? location.pathname === "/"
                      : location.pathname.startsWith(option.route),
                })}
              >
                {option.icon}
                <div>{option.label}</div>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

export default NavigationSideBar;
