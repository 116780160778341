import classNames from "classnames"
import React, { useState } from "react"
import { Carousel } from "react-responsive-carousel"
import { useIsMobile } from "../../hooks/isMobile"

import FormInput from "../FormInput/FormInput"
import Modal from "../Modal/Modal"
import styles from "./Post.module.scss"

const { ColorExtractor } = require("react-color-extractor")

interface Props {
  title: string
  caption: string
  firstImage?: number
  media: {
    image?: string
    icon?: JSX.Element
  }[]
  fullSize?: boolean
  focussed?: boolean
  focusOnly?: boolean
  editable?: boolean
  onCancel?: () => void
}

function Post(props: Props) {
  const {
    title,
    caption,
    media,
    firstImage,
    focussed,
    focusOnly,
    editable,
    onCancel,
    fullSize,
  } = props
  const { isMobile } = useIsMobile()
  const [isFocussed, setIsFocussed] = useState(focussed || focusOnly)
  const [currentImage, setCurrentImage] = useState<string>(
    media[firstImage ?? 0].image ?? ""
  )
  const [currentImageColors, setCurrentImageColors] = useState<string>("")

  const carousel = (inFocus: boolean) => (
    <Carousel
      emulateTouch
      verticalSwipe="natural"
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={10}
      showArrows={!isMobile}
      showIndicators={media.length > 1}
      onChange={(index) => {
        setCurrentImage(media[index].image as string)
      }}
      showThumbs={false}
      showStatus={false}
      selectedItem={firstImage}
      onClickItem={(e) => {
        setIsFocussed(true)
      }}
      width={
        document.body.clientWidth < 400
          ? fullSize
            ? "100vw"
            : "calc(100vw - 40px)"
          : inFocus
          ? "calc(100vh - 300px)"
          : 600
      }
    >
      {media.map((thumbnail, index) => {
        if (!editable) {
          return (
            <div
              key={index}
              className={styles.image}
              style={{
                background: `url(${thumbnail.image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
                height:
                  document.body.clientWidth < 400
                    ? fullSize
                      ? "100vw"
                      : "calc(100vw - 40px)"
                    : inFocus
                    ? "calc(100vh - 300px)"
                    : 600,
              }}
            />
          )
        }
        return (
          <div className={styles.image}>
            {thumbnail.image ? (
              <img src={thumbnail.image} alt={title} />
            ) : (
              thumbnail.icon
            )}
          </div>
        )
      })}
    </Carousel>
  )

  const postBottom = caption ? (
    <div className={styles.postBottom}>
      {isMobile && <h2>{title}</h2>}
      <div className={styles.description}>
        <p>{caption}</p>
      </div>
    </div>
  ) : null

  if (editable) {
    // assumes editable is never fullsize
    return (
      <div
        className={classNames(styles.main)}
        style={
          currentImageColors ? { backgroundColor: currentImageColors } : {}
        }
      >
        {currentImage && (
          <ColorExtractor
            src={currentImage}
            getColors={(colors: string[]) => setCurrentImageColors(colors[0])}
          />
        )}
        {carousel(false)}
        <div
          className={styles.description}
          style={{ maxWidth: document.body.clientWidth - 68 }}
        >
          <FormInput
            key="title"
            name="title"
            type="text"
            label="Title"
            maxLength={40}
            rows={1}
            textarea
          />
          <FormInput
            key="caption"
            name="caption"
            type="text"
            label="Caption"
            maxLength={255}
            rows={2}
            textarea
          />
        </div>
      </div>
    )
  }

  const post = (inFocus: boolean) => (
    <div
      className={classNames(styles.main, {
        [styles.fullSize]: fullSize,
        [styles.isFocussed]: !editable && inFocus,
      })}
      style={currentImageColors ? { backgroundColor: currentImageColors } : {}}
    >
      {currentImage && (
        <ColorExtractor
          src={currentImage}
          getColors={(colors: string[]) => setCurrentImageColors(colors[0])}
        />
      )}
      {carousel(inFocus)}
      {(title || caption) && postBottom}
    </div>
  )

  return (
    <>
      <Modal
        maxWidth
        visible={!!isFocussed}
        onCancel={() => {
          setIsFocussed(false)
          if (onCancel) onCancel()
        }}
      >
        {!isMobile && title ? <h2>{title}</h2> : <></>}
        {post(true)}
      </Modal>
      {!focusOnly && post(false)}
    </>
  )
}

export default Post
