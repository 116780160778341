import React from "react"
import classnames from "classnames"

import styles from "./Button.module.scss"

interface ButtonProps {
  children: (JSX.Element | string) | (JSX.Element | string)[]
  color: "blue" | "grey" | "purple" | "glowing" | string
  size?: "large" | "default" | "small"
  disabled?: boolean
  ghost?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

function Button(props: ButtonProps) {
  const { children, color, size, disabled, ghost, onClick } = props

  return (
    <div
      onClick={(e) => {
        if (!disabled && onClick) onClick(e)
      }}
      className={classnames(styles.main, {
        [styles.blue]: color === "blue",
        [styles.grey]: color === "grey",
        [styles.purple]: color === "purple",
        [styles.green]: color === "green",
        [styles.red]: color === "red",
        [styles.large]: size === "large",
        [styles.small]: size === "small",
        [styles.disabled]: disabled,
        [styles.ghost]: ghost,
      })}
      style={!(color in styles) ? { backgroundColor: color } : {}}
    >
      {children}
    </div>
  )
}

export default Button
