import { overwriteFile } from "@inrupt/solid-client"
import { Session } from "@inrupt/solid-client-authn-browser"
import { NamedNode } from "rdflib"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { toast } from "react-toastify"
import { v4 as uuid } from "uuid"

import { PodConnectorContext } from "react-pod-connector"
import {
  DataDiscoveryContext,
  ImportedData,
} from "../context/DataDiscoveryContext"
import {
  ArchiveShape,
  CollectionIndexEntryShape,
  PostIndexEntryShape,
  PostIndexEntryShapeType,
  PostShape,
  PostShapeType,
  collection,
  post,
  postIndexEntry,
} from "../generated/shex"
import { CreatePostInput } from "../pages/UploadPage/UploadPage"

export const useCreatePost = () => {
  const { session } = useContext(PodConnectorContext)
  const [progress, setProgress] = useState(0)

  const {
    importedData: { archive },
    setImportedData,
  } = useContext(DataDiscoveryContext)

  if (!archive) {
    toast("No archive found.", { type: "error" })
    return {}
  }

  return {
    progress,
    createPost: createPost(
      archive,
      setProgress,
      setImportedData as Dispatch<SetStateAction<ImportedData>>,
      session as unknown as Session
    ),
  }
}

const fetchThesePosts = async (
  posts: PostIndexEntryShape[],
  storageAddress: string,
  session: Session
) => {
  try {
    if (storageAddress)
      return await new Promise<DisplayPost[]>((resolve, reject) => {
        if (posts?.length) {
          fetchPostsOf(posts, storageAddress, session)
            .then(resolve)
            .catch(reject)
        }
      })
  } catch {
    console.debug("Error loading Home page")
  }
}

export const useFetchPosts = () => {
  const [myPosts, setMyPosts] = useState<DisplayPost[]>([])
  const {
    storageAddresses,
    importedData: { posts },
  } = useContext(DataDiscoveryContext)
  const { session } = useContext(PodConnectorContext)
  const [loading, setLoading] = useState(false)

  const fetchMyPosts = async () => {
    if (posts?.length && storageAddresses?.length && !loading && session) {
      setLoading(true)
      fetchThesePosts(posts, storageAddresses[0], session as unknown as Session)
        .then((posts) => {
          if (posts) {
            setMyPosts(posts)
            setLoading(false)
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  return {
    loading,
    myPosts,
    fetchMyPosts,
  }
}

export interface DisplayPost {
  id: string
  title: string
  caption: string
  media: {
    url: string
    image: string
  }[]
}

export const getNewIndexEntryPathFor = (
  shapeName: string,
  storage: string,
  folder: string
) => {
  const folderPathSplit = new URL(folder).pathname.split("/")
  folderPathSplit.pop()
  const folderPath = folderPathSplit.join("/")
  const id = new URL(
    `${folderPath}/${shapeName}/${uuid()}/index.ttl#this`,
    storage
  ).href
  return id
}

function fetchPostsOf(
  postIndices: PostIndexEntryShape[],
  storage: string,
  session: Session
) {
  return new Promise<DisplayPost[]>(async (resolve, reject) => {
    if (postIndices?.length) {
      post.fetcher._fetch = session.fetch
      Promise.allSettled(
        postIndices.map((p) => {
          return post
            .findOne({
              where: { id: p.id },
              doc: new NamedNode(p.id).doc().uri,
            })
            .then((p) => p.data)
        })
      )
        .then((posts) => {
          if (!posts.length) resolve([])
          const foundPosts = posts
            .map((p) => p.status === "fulfilled" && p.value)
            .filter((p) => Boolean(p)) as PostShape[]
          if (foundPosts)
            return fetchPosts(foundPosts, postIndices, storage, session)
        })
        .catch((e) => {
          reject(e)
        })
        .then((posts) => {
          if (posts) resolve(posts)
        })
    } else {
      resolve([])
    }
  })
}

async function fetchPosts(
  posts: PostShape[],
  postIndices: PostIndexEntryShape[],
  storage: string,
  session: Session
) {
  return await Promise.all(
    posts.map(async (p) => {
      const meta = postIndices.find((i) => i.id === p.id)
      const media = await Promise.all(
        (Array.isArray(p.media) ? p.media : p.media ? [p.media] : []).map(
          (m) => {
            const inStorage = new URL(m).host === new URL(storage).host
            const imageFetch = inStorage ? session.fetch : fetch
            return imageFetch(decodeURIComponent(m) as string, {
              credentials: "omit",
            }).then((image) => {
              return image.blob().then((i) => {
                const image = URL.createObjectURL(i)
                return { image, url: m }
              })
            })
          }
        )
      )
      return {
        id: meta?.id as string,
        title: meta?.title as string,
        caption: meta?.caption as string,
        media,
      }
    })
  )
}

function createPost(
  archive: ArchiveShape,
  setProgress: React.Dispatch<SetStateAction<number>>,
  setImportedData: React.Dispatch<SetStateAction<ImportedData>>,
  session: Session
) {
  return async (
    args: CreatePostInput,
    author: string,
    storage: string,
    into: CollectionIndexEntryShape
  ) => {
    post.fetcher._fetch = session.fetch

    // Create Post and upload media

    const combinedMediaSize = args.acceptedMedia.reduce((fullSize, media) => {
      return fullSize + media.file.size
    }, 0)
    let uploadedMediaSize = 0
    let uploadedMedia: URL[] = []
    const postId = getNewIndexEntryPathFor("Post", storage, archive.id)
    const postPathSplit = new URL(postId).pathname.split("/")
    postPathSplit.pop()
    const postPath = postPathSplit.join("/")

    new Promise<void>(async (resolve) => {
      async function upload(id: URL, m: { file: File }) {
        try {
          const res = await overwriteFile(id.href, m.file, {
            contentType: m.file.type,
            fetch: session.fetch,
          })
          uploadedMedia.push(new URL(res.internal_resourceInfo.sourceIri))
          uploadedMediaSize += m.file.size
          setProgress((uploadedMediaSize / combinedMediaSize) * 100)
        } catch {
          setProgress((uploadedMediaSize / combinedMediaSize) * 100)
          toast("Error uploading " + m.file.name, { type: "error" })
        }
      }
      for (let i = 0; i < args.acceptedMedia.length; i++) {
        const m = args.acceptedMedia[i]
        const id = new URL(
          `${postPath}/${encodeURIComponent(m.file.name)}`,
          storage
        )
        if (m.link) {
          uploadedMedia.push(new URL(m.link))
        } else {
          await upload(id, m)
        }
      }
      resolve()
    }).then(() => {
      post.create({
        data: { id: postId, type: PostShapeType.Post, media: uploadedMedia },
        doc: new NamedNode(postId).doc().uri,
      })
    })

    // Create Post index
    postIndexEntry.fetcher._fetch = session.fetch
    const postIndex = await postIndexEntry
      .create({
        data: {
          id: postId,
          type: PostIndexEntryShapeType.PostIndexEntry,
          inArchive: new URL(archive.id),
          title: args.title,
          caption: args.caption,
        },
        doc: archive.postIndex,
      })
      .then(({ data }) => data)
      .catch(() => toast("Error creating post index", { type: "error" }))
      
    // Update collection
    collection.fetcher._fetch = session.fetch
    const intoCollection = await collection.findOne({
      where: { id: into.id },
      doc: new NamedNode(into.id).doc().uri,
    })
    if (intoCollection.data) {
      const newCollectionPosts = [
        ...(Array.isArray(intoCollection.data?.holds)
          ? intoCollection.data?.holds
          : intoCollection.data?.holds
          ? [intoCollection.data?.holds]
          : []
        ).map((holds) => new URL(holds)),
        new URL(postId),
      ]
      await collection
        .update({
          data: {
            id: into.id,
            holds: newCollectionPosts,
          },
          doc: new NamedNode(into.id).doc().uri,
        })
        .then(() => {
          if (postIndex) {
            setImportedData((imports) => ({
              ...imports,
              posts: [
                ...(imports.posts ?? []),
                postIndex as PostIndexEntryShape,
              ],
            }))
          }
          toast(`Successfully added post to "${into.title}"`, {
            type: "success",
          })
        })
        .catch(() =>
          toast("Error adding post to the collection", { type: "error" })
        )
    }

    setProgress(100)
  }
}

export const useSharePost = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = useState(0)

  return { progress, sharePost }
}

const sharePost = (post: CreatePostInput, author: string, storage: string) => {
  console.debug(post, author, storage)
}
