import { logout } from "@inrupt/solid-client-authn-browser"
import { useContext, useState } from "react"
import { LogOut } from "react-feather"
import { PodConnectorContext } from "react-pod-connector"

import { useNavigate } from "react-router"
import { DataDiscoveryContext } from "../../context/DataDiscoveryContext"
import { getUsernameFromWebId } from "../../helpers/username"
import Button from "../Button/Button"
import Modal from "../Modal/Modal"
import styles from "./LoginIndicator.module.scss"

function LoginIndicator() {
  const navigate = useNavigate()
  const { session } = useContext(PodConnectorContext)
  const { storageAddresses } = useContext(DataDiscoveryContext)
  const [logoutVisible, setLogoutVisible] = useState(false)

  if (!session) {
    return null
  }

  return (
    <div className={styles.main}>
      <div className={styles.status}>
        {session.info.webId && (
          <>
            Logged in as <b>{getUsernameFromWebId(session.info.webId)}</b>
          </>
        )}
        <div className={styles.storages}>
          {" Using "}
          {storageAddresses
            ? storageAddresses?.map((s) => (
                <a href={s} key={s}>
                  {new URL(s).host}
                </a>
              ))
            : ""}
        </div>
      </div>
      <div className={styles.logout} onClick={() => setLogoutVisible(true)}>
        <LogOut />
      </div>
      <Modal visible={logoutVisible} onCancel={() => setLogoutVisible(false)}>
        <div className={styles.logoutForm}>
          Are you sure you want to logout?
          <div className={styles.buttons}>
            <Button color="grey" onClick={() => setLogoutVisible(false)}>
              Cancel
            </Button>
            <Button
              color="red"
              onClick={() => {
                logout().then(() => {
                  localStorage.removeItem("discoveredData")
                  setLogoutVisible(false)
                  navigate(0)
                })
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default LoginIndicator
