import { useFormikContext } from "formik"
import React, { useContext, useState } from "react"
import { toast } from "react-toastify"
import { PodConnectorContext } from "react-pod-connector"
import { NamedNode } from "rdflib"

import styles from "./NewCollectionForm.module.scss"

import Button from "../../../../components/Button/Button"
import FormInput from "../../../../components/FormInput/FormInput"
import { DataDiscoveryContext } from "../../../../context/DataDiscoveryContext"
import {
  CollectionIndexEntryShapeType,
  CollectionShapeType,
  collection,
  collectionIndexEntry,
} from "../../../../generated/shex"
import { getNewIndexEntryPathFor } from "../../../../hooks/post"


interface NewCollectionFormProps {}

const NewCollectionForm: React.FC<NewCollectionFormProps> = () => {
  const [visible, setVisible] = useState(false)
  const { values, setFieldValue } = useFormikContext<{
    collection: string
    newCollection: string
  }>()
  const { setImportedData, storageAddresses, importedData } =
    useContext(DataDiscoveryContext)
  const { archive } = importedData
  const { session } = useContext(PodConnectorContext)

  return (
    <div className={styles.main}>
      <h4 onClick={() => setVisible(!visible)}>Into a new collection</h4>
      {visible ? (
        <div className={styles.input}>
          <FormInput
            name="newCollection"
            label="My new collection"
            type="text"
          />
          <Button
            color="green"
            disabled={!values.newCollection}
            onClick={async () => {
              if (
                storageAddresses &&
                storageAddresses[0] &&
                archive?.id &&
                session
              ) {
                collection.fetcher._fetch = session.fetch
                collectionIndexEntry.fetcher._fetch = session.fetch
                const id = getNewIndexEntryPathFor(
                  "Collection",
                  storageAddresses[0],
                  archive?.id
                )
                const newCollection = await collection.create({
                  data: {
                    id,
                    type: CollectionShapeType.Collection,
                  },
                  doc: new NamedNode(id).doc().uri,
                })
                if (newCollection.data?.id) {
                  const newCollectionIndex = await collectionIndexEntry.create({
                    data: {
                      id: newCollection.data?.id,
                      type: CollectionIndexEntryShapeType.CollectionIndexEntry,
                      inArchive: new URL(archive.id),
                      title: values.newCollection,
                    },
                    doc: archive.collectionIndex,
                  })
                  if (
                    newCollection.data &&
                    newCollectionIndex.data &&
                    setImportedData &&
                    Array.isArray(importedData.collections)
                  ) {
                    setImportedData({
                      ...importedData,
                      collections: [
                        ...importedData.collections,
                        newCollectionIndex.data,
                      ],
                    })
                    setFieldValue("collection", newCollectionIndex.data)
                    setFieldValue("newCollection", "")
                    setVisible(false)
                    toast(
                      `Successfully created new collection called "${newCollectionIndex.data.title}"`,
                      {
                        type: "success",
                      }
                    )
                  }
                }
              }
            }}
          >
            Create
          </Button>
        </div>
      ) : null}
    </div>
  )
}

export default NewCollectionForm
