import { Formik } from "formik"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import Button from "../../components/Button/Button"
import EmptyView from "../../components/EmptyView/EmptyView"
import FormInput from "../../components/FormInput/FormInput"
import Modal from "../../components/Modal/Modal"
import { getUsernameFromWebId } from "../../helpers/username"
import { useCreateContact, useFetchContacts } from "../../hooks/contacts"

import Page from "../Page"

import styles from "./ContactsPage.module.scss"

function ContactsSidebar() {
  return (
    <aside>
      <p>
        Adding someone to your contacts allows you to share media with them. You
        can add a contact by entering their WebId.
      </p>
      <p>
        People that shared something with you in the past will also appear here.
      </p>
    </aside>
  )
}

function ContactsPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const { loading, createContact } = useCreateContact()
  const { contacts } = useFetchContacts()

  return (
    <Page title="The Projektor" rightSidebar={<ContactsSidebar />}>
      <Modal
        loading={loading}
        onCancel={() => navigate("/contacts")}
        visible={location.pathname.endsWith("/contacts/add")}
      >
        <Formik
          initialValues={{ webId: "" }}
          onSubmit={(values) => {
            createContact(values.webId).then(() => {
              navigate("/contacts")
            })
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <p>Which WebId would you like to add to your contacts?</p>
              <FormInput type="text" name="webId" label="WebId"></FormInput>
              <Button color="green" onClick={() => handleSubmit()}>
                Add
              </Button>
            </form>
          )}
        </Formik>
      </Modal>
      {contacts.length ? (
        <div className={styles.main}>
          <Link to="/contacts/add">
            <Button color="green">Add a new contact</Button>
          </Link>
          <div className={styles.contacts}>
            {contacts.map((c) => {
              return (
                <h4
                  className={styles.contact}
                  onClick={() => {
                    navigate("/account/" + encodeURIComponent(c.webId))
                  }}
                >
                  {getUsernameFromWebId(c.webId)}
                </h4>
              )
            })}
          </div>
        </div>
      ) : (
        <EmptyView
          message="It looks like you don't have any contacts yet."
          actions={
            <div className={styles.emptyActions}>
              <Link to="/contacts/add">
                <Button color="green">Add a new contact</Button>
              </Link>
            </div>
          }
        />
      )}
    </Page>
  )
}

export default ContactsPage
