import { Formik } from "formik"
import { useContext, useEffect, useState } from "react"
import { PodConnectorContext } from "react-pod-connector"
import { useNavigate } from "react-router"
import Button from "../../../../components/Button/Button"
import SelectMenu from "../../../../components/SelectMenu/SelectMenu"
import Switch from "../../../../components/Switch/Switch"
import { DataDiscoveryContext } from "../../../../context/DataDiscoveryContext"
import { getUsernameFromWebId } from "../../../../helpers/username"
import { useCreatePost, useSharePost } from "../../../../hooks/post"
import { CreatePostInput } from "../../UploadPage"
import NewCollectionForm from "./NewCollectionForm"
import styles from "./UploadForm.module.scss"

interface Props {
  post: CreatePostInput
}

export const getAbbreviatedStorageName = (storage: string) => {
  const storageUrl = new URL(storage)
  const host = storageUrl.host
  const folder = storageUrl.pathname.split("/")[1]
  return `${folder ? folder : "Root"} @ ${host}`
}

function UploadForm(props: Props) {
  const { post } = props
  const navigate = useNavigate()
  const [formState, setFormState] = useState<"place" | "upload" | "share">(
    "place"
  )
  const { session } = useContext(PodConnectorContext)
  const { webId } = session?.info ?? {}
  const { storageAddresses, importedData } = useContext(DataDiscoveryContext)
  const [usedStorageAddress, setUsedStorageAddress] = useState(
    storageAddresses?.at(0)
  )
  const { progress: progressCreating, createPost } = useCreatePost()
  const { progress: progressSharing, sharePost } = useSharePost()

  useEffect(() => {
    if (progressCreating === 100) {
      setFormState("share")
    }
  }, [progressCreating])

  if (formState === "place") {
    return (
      <div className={styles.main}>
        <Formik
          initialValues={{
            storage: storageAddresses?.at(0),
            collection: importedData.collections?.at(0),
          }}
          onSubmit={(values) => {
            if (createPost && values.storage && values.collection) {
              createPost(
                post,
                webId as string,
                values.storage,
                values.collection
              ).catch(console.debug)
            }
            setUsedStorageAddress(values.storage)
          }}
        >
          {({ handleSubmit }) => (
            <form>
              <h4>Where do you want to store this post? 🏘</h4>
              <SelectMenu
                name="storage"
                options={
                  storageAddresses?.map((s, i) => ({
                    label: `${
                      i === 0 ? "(default) " : ""
                    }${getAbbreviatedStorageName(s)}`,
                    value: s,
                  })) ?? []
                }
              />
              <h4>In which collection should this post go? 🗃</h4>
              <SelectMenu
                name="collection"
                options={
                  [...(importedData.collections ?? [])]?.map((c, i) => ({
                    label: `${i === 0 ? "(default) " : ""}${c.title}`,
                    value: c.id,
                  })) ?? []
                }
              />
              <NewCollectionForm />
              <Button
                color="blue"
                onClick={() => {
                  handleSubmit()
                  setFormState("upload")
                }}
              >
                Start Upload
              </Button>
            </form>
          )}
        </Formik>
      </div>
    )
  }

  if (formState === "upload") {
    return (
      <div className={styles.main}>
        <label htmlFor="file-upload">Adding Post to your archive...</label>
        <progress
          id="file-upload"
          value={progressCreating}
          max={100}
        ></progress>
      </div>
    )
  }

  if (formState === "share") {
    return (
      <div className={styles.main}>
        <Formik
          initialValues={{ contacts: [], public: false }}
          onSubmit={(values) => {
            sharePost(post, webId as string, usedStorageAddress as string)
            navigate("/")
          }}
        >
          {({ handleSubmit, values }) => (
            <form>
              <h4>Share this post with some of your contacts? 👥</h4>
              <SelectMenu
                multiple
                name="contacts"
                options={
                  importedData.contacts?.map((s) => ({
                    label: getUsernameFromWebId(s.url.value),
                    value: s.url.value,
                  })) ?? []
                }
              />
              <Switch
                label={<h4>Share this post on your public profile? 🌎</h4>}
              />
              <Button
                color="blue"
                onClick={() => {
                  handleSubmit()
                }}
              >
                {values.contacts.length || values.public ? "Share" : "Finish"}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    )
  }

  return (
    <div className={styles.main}>
      <label htmlFor="file-upload">Sharing your post...</label>
      <progress id="file-upload" value={progressSharing} max={100}></progress>
    </div>
  )
}

export default UploadForm
