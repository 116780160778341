import { Formik } from "formik"
import { useContext } from "react"
import FormInput from "../../components/FormInput/FormInput"
import { getUsernameFromWebId } from "../../helpers/username"
import Page from "../Page"

import { PodConnectorContext } from "react-pod-connector"
import styles from "./PreferencesPage.module.scss"

function PreferencesSidebar() {
  return (
    <aside>
      <p>
        You can change your preferences anytime and they will be saved to your
        account so you can reuse them in other apps.
      </p>
      {/* <p>
        You can add media to your profile by publishing something in your
        archive.
      </p> */}
    </aside>
  )
}

function PreferencesPage() {
  const { session } = useContext(PodConnectorContext)
  const { webId } = session?.info ?? {}

  return (
    <Page title="The Projektor" rightSidebar={<PreferencesSidebar />}>
      <div className={styles.main}>
        <Formik
          initialValues={{ name: "", about: "" }}
          onSubmit={console.debug}
        >
          <form className={styles.formSection}>
            <h2 className={styles.header}>Privacy</h2>
            <h4>Others can</h4>
            <h4>View your profile</h4>
            <FormInput
              type="text"
              name="name"
              maxLength={44}
              label="Yes/Contacts only/No"
            />
            <h4>Find you via username</h4>
            <FormInput
              type="text"
              name="about"
              label="Yes/Contacts only/No"
              maxLength={128}
            />
            <div className={styles.divider}></div>
          </form>
        </Formik>
        <Formik
          initialValues={{ name: "", about: "" }}
          onSubmit={console.debug}
        >
          <form className={styles.formSection}>
            <h2 className={styles.header}>Profile</h2>
            <h4>Display Name</h4>
            <FormInput
              type="text"
              name="name"
              maxLength={44}
              label={getUsernameFromWebId(webId as string)}
            />
            <h4>About</h4>
            <FormInput
              textarea
              type="text"
              name="about"
              label="An optional text that describes you, which will be displayed on your profile"
              maxLength={128}
            />
            <div className={styles.divider}></div>
          </form>
        </Formik>
        <Formik
          initialValues={{ name: "", about: "" }}
          onSubmit={console.debug}
        >
          <form className={styles.formSection}>
            <h2 className={styles.header}>Archive</h2>
            <h4>Archive Location</h4>
            <FormInput
              type="text"
              name="name"
              maxLength={44}
              label="/archive"
            />
            <h4>Archive Index</h4>
            <FormInput
              type="text"
              name="about"
              label="An optional text that describes you, which will be displayed on your profile"
              maxLength={128}
            />
            <div className={styles.divider}></div>
          </form>
        </Formik>
        <Formik
          initialValues={{ name: "", about: "" }}
          onSubmit={console.debug}
        >
          <form className={styles.formSection}>
            <h2 className={styles.header}>Messages</h2>
            <h4>Allow Messages From</h4>
            <FormInput
              type="text"
              name="name"
              maxLength={44}
              label="Everyone, Contacts only, Specified only"
            />
            <div className={styles.divider}></div>
          </form>
        </Formik>
      </div>
    </Page>
  )
}

export default PreferencesPage
