import classNames from "classnames"
import { useEffect, useState } from "react"
import { useClickOutside } from "react-click-outside-hook"
import { XCircle } from "react-feather"
import { useIsMobile } from "../../hooks/isMobile"
import styles from "./Modal.module.scss"

interface Props {
  visible: boolean
  loading?: boolean
  maxWidth?: boolean
  onCancel?: () => void
  children: JSX.Element | JSX.Element[] | string
}

function Modal(props: Props) {
  const { visible, loading, maxWidth, onCancel, children } = props
  const [ref, hasClickedOutside] = useClickOutside()
  const [closeListenerActive, setCloseListenerActive] = useState(false)
  const { isMobile } = useIsMobile()

  useEffect(() => {
    if (!closeListenerActive) {
      setTimeout(() => {
        setCloseListenerActive(true)
      }, 1000)
    }
  }, [closeListenerActive])

  const closeCallback = () => {
    if (onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    if (closeListenerActive && hasClickedOutside) {
      closeCallback()
    } else if (hasClickedOutside) {
      setCloseListenerActive(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasClickedOutside])

  return (
    <div
      className={classNames(styles.main, {
        [styles.hidden]: !visible,
        [styles.maxWidth]: maxWidth,
        [styles.loading]: loading,
      })}
      onClick={() => {
        if (closeListenerActive && hasClickedOutside) {
          closeCallback()
        }
      }}
    >
      <div ref={ref} className={styles.body}>
        <progress className={styles.progress} />
        {isMobile && (
          <div className={styles.close}>
            <XCircle fill="whitesmoke" onClick={closeCallback} />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default Modal
