import { Formik } from "formik"
import { createContext, useContext, useEffect, useMemo, useState } from "react"

import styles from "./DataPolicyPrompt.module.scss"

import {
  buildThing,
  createSolidDataset,
  createThing,
  saveSolidDatasetAt,
  setThing,
  SolidDataset,
  universalAccess,
} from "@inrupt/solid-client"
import { AccessModes } from "@inrupt/solid-client/dist/acp/policy"
import { LDP, RDF } from "@inrupt/vocab-common-rdf"
import classNames from "classnames"
import { toast } from "react-toastify"
import { Shape } from "shex-methods"
import { DataDiscoveryContext } from "../../context/DataDiscoveryContext"
import { getAbbreviatedStorageName } from "../../pages/UploadPage/components/UploadForm/UploadForm"
import Button from "../Button/Button"
import SelectMenu from "../SelectMenu/SelectMenu"
import { sentenceCase } from "change-case"
import { NamedNode } from "rdflib"
import { PodConnectorContext } from "react-pod-connector"

export interface DataPromptStructure<T, A> {
  name: string
  shape: Shape<T, A>
  args: A
  node: string
  path: string
  storage: URL
  access: Partial<AccessModes>
  additionalFiles?: {
    url: URL
    access: Partial<AccessModes>
    data?: SolidDataset
  }[]
  onExist: (s: T) => void
}

export type PromptProgress =
  | "checking"
  | "creating"
  | "needed"
  | "damaged"
  | "inaccessible"
  | "existing"

function renderPromptStatus(
  progress: PromptProgress,
  onCreate: () => void,
  onCreatePermissions: () => void
) {
  switch (progress) {
    case "needed":
      return (
        <Button color="grey" onClick={onCreate}>
          Create 🛠
        </Button>
      )
    case "damaged":
      return "Exists but is not usable ❌"
    case "existing":
      return (
        <Button color="green" disabled>
          Exists ✅
        </Button>
      )
    case "inaccessible":
      return (
        <Button color="grey" onClick={onCreatePermissions}>
          Change Permissions 🛠
        </Button>
      )
    case "creating":
      return "Creating..."
    default:
      return "Checking..."
  }
}

function renderAccessNeeded(access: Partial<AccessModes>) {
  let accessNeeded = ""
  if (access.read) {
    accessNeeded += "Read"
  }
  if (access.write && !access.append) {
    accessNeeded += " & Write Access"
  } else if (access.write && access.append) {
    accessNeeded += ", Write & Append Access"
  }

  return accessNeeded
}

export const includesAccess = (
  access: AccessModes,
  includes: Partial<AccessModes>
) =>
  !Object.keys(access).find(
    (mode) =>
      (includes as Record<string, boolean>)[mode] !==
        (access as Record<string, boolean>)[mode] &&
      (includes as Record<string, boolean>)[mode]
  )

export function DataPrompt<T, A>(prompt: DataPromptStructure<T, A>) {
  const [progress, setProgress] = useState<PromptProgress>("checking")
  const [needAdditionalFiles, setNeedsAdditionalFiles] = useState<
    DataPromptStructure<T, A>["additionalFiles"] | undefined
  >()
  const [existingShape, setExistingShape] = useState<T | undefined>()
  const { onExist, onNotExist } = useContext(PolicyFormContext)

  const setProgressAfterCheck = useMemo(
    () => (progress: PromptProgress) => {
      setTimeout(() => setProgress(progress), 500)
    },
    [setProgress]
  )

  const { session } = useContext(PodConnectorContext)

  useEffect(() => {
    if (progress === "needed") {
      if (onNotExist) onNotExist(prompt.shape.id)
    }

    if (progress === "checking" && session) {
      prompt.shape.fetcher._fetch = session.fetch

      const doc = new URL("." + prompt.path, prompt.storage.href).href
      const checkShapeAtPath = prompt.shape.findOne({
        where: { id: prompt.node },
        doc: doc,
      })

      let inAccessibleFiles = false
      let additionalFilesNeeded: {
        url: URL
        access: Partial<AccessModes>
        data?: SolidDataset
      }[] = []
      if (prompt.additionalFiles) {
        Promise.allSettled(
          prompt.additionalFiles.map((file) => {
            const { url } = file
            return universalAccess.getAgentAccess(
              url.href,
              session.info.webId as string,
              {
                fetch: session.fetch,
              }
            )
          })
        ).then((res) => {
          res.forEach((actual, i) => {
            if (
              (actual.status === "fulfilled" &&
                actual.value &&
                prompt.additionalFiles &&
                !includesAccess(
                  actual.value,
                  prompt.additionalFiles[i].access
                )) ||
              (actual.status === "fulfilled" && !actual.value)
            ) {
              inAccessibleFiles = true
            } else if (actual.status === "rejected" && prompt.additionalFiles) {
              additionalFilesNeeded.push(prompt.additionalFiles[i])
            }
          })
          setNeedsAdditionalFiles(additionalFilesNeeded)
        })
      }

      if (existingShape) {
        if (additionalFilesNeeded.length) {
          setProgressAfterCheck("needed")
        } else if (inAccessibleFiles) {
          setProgressAfterCheck("inaccessible")
        } else {
          if (onExist) onExist(prompt.shape.id)
          if (prompt.onExist) prompt.onExist(existingShape)
          setProgressAfterCheck("existing")
        }
      } else {
        checkShapeAtPath
          .then(async (res) => {
            if (res.errors) {
              console.debug(res.errors, res.doc)
              if (res.errors[0].startsWith("No shapes found"))
                setProgressAfterCheck("needed")
              else {
                if (
                  prompt.shape.store.statementsMatching(
                    new NamedNode(prompt.node)
                  ).length === 0
                ) {
                  setProgressAfterCheck("needed")
                } else {
                  setProgressAfterCheck("damaged")
                }
              }
            } else if (res.data) {
              universalAccess
                .getAgentAccess(
                  res.doc as string,
                  session.info.webId as string,
                  {
                    fetch: session.fetch,
                  }
                )
                .then((actual) => {
                  setExistingShape(res.data)
                  if (!actual) setProgressAfterCheck("inaccessible")
                  else if (
                    !includesAccess(actual, prompt.access) ||
                    inAccessibleFiles
                  ) {
                    setProgressAfterCheck("inaccessible")
                  } else if (!additionalFilesNeeded.length) {
                    if (onExist) onExist(prompt.shape.id)
                    if (prompt.onExist) prompt.onExist(res.data as T)
                    setProgressAfterCheck("existing")
                  } else if (additionalFilesNeeded) {
                    setProgressAfterCheck("needed")
                  }
                })
            } else {
              setProgressAfterCheck("needed")
            }
          })
          .catch((e) => {
            if (e.status === 404) {
              setProgressAfterCheck("needed")
            }
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])

  const onCreate = useMemo(() => {
    return async () => {
      setProgress("creating")
      if (session) {
        prompt.shape.fetcher._fetch = session.fetch
      }
      const doc = new URL("." + prompt.path, prompt.storage.href).href
      if (!existingShape && session) {
        await prompt.shape
          .create({
            data: {
              id: prompt.node,
              ...prompt.args,
            },
            doc,
          })
          .then(({ data, errors }) => {
            if (!errors) {
              return universalAccess
                .setAgentAccess(
                  doc,
                  session.info.webId as string,
                  prompt.access,
                  { fetch: session.fetch }
                )
                .then(() => {
                  if (!needAdditionalFiles?.length)
                    setProgressAfterCheck("checking")
                  setExistingShape(data)
                })
            } else {
              console.debug(errors, doc, prompt.args)
              toast("There was an error creating the " + prompt.name + ".", {
                type: "error",
              })
            }
          })
          .catch(() => {
            toast("There was an error creating the " + prompt.name + ".", {
              type: "error",
            })
          })
      }

      if (needAdditionalFiles && session) {
        await Promise.all(
          needAdditionalFiles.map(async (addFile) => {
            let ds = createSolidDataset()
            const file = buildThing(createThing({ url: addFile.url.href }))
              .addUrl(RDF.type, LDP.Resource)
              .build()
            ds = setThing(ds, file)
            try {
              await saveSolidDatasetAt(addFile.url.href, ds, {
                fetch: session.fetch,
              })
              universalAccess.setAgentAccess(
                addFile.url.href,
                session.info.webId as string,
                addFile.access,
                { fetch: session.fetch }
              )
              setProgressAfterCheck("checking")
            } catch (e) {
              toast(
                "There was an error creating the files that are needed for the " +
                  prompt.name +
                  ".",
                {
                  type: "error",
                }
              )
              setProgressAfterCheck("checking")
            }
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needAdditionalFiles, existingShape])

  const onCreatePermissions = useMemo(() => {
    return async () => {
      if (prompt.additionalFiles && session) {
        await Promise.all(
          prompt.additionalFiles.map(({ url, access }) => {
            return universalAccess.setAgentAccess(
              url.href,
              session.info.webId as string,
              access,
              { fetch: session.fetch }
            )
          })
        )
      } else if (session) {
        await universalAccess.setAgentAccess(
          new URL("." + prompt.path, prompt.storage.href).href,
          session.info.webId as string,
          prompt.access,
          { fetch: session.fetch }
        )
      }
      setProgress("checking")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.prompt}>
      <div>
        <b>{prompt.name}</b>
        <p>{renderAccessNeeded(prompt.access)}</p>
      </div>
      <div
        className={classNames(styles.status, {
          [styles.checking]: progress === "checking",
          [styles.needed]: progress === "needed",
          [styles.damaged]: progress === "damaged",
          [styles.existing]: progress === "existing",
        })}
      >
        {renderPromptStatus(progress, onCreate, onCreatePermissions)}
      </div>
    </div>
  )
}

interface Props {
  onFinish: (storage: string) => void
  onFail: () => void
  children: (storage: string) => JSX.Element[]
  hidden?: boolean
}

const PolicyFormContext = createContext<{
  onExist?: (shape: string) => void
  onNotExist?: (shape: string) => void
}>({})

function DataPolicyPrompt({ onFinish, onFail, hidden, children }: Props) {
  const { storageAddresses, importedData } = useContext(DataDiscoveryContext)
  const savedStorageAddress = localStorage.getItem("usedStorageAddress")
  const [usedStorageAddress, setUsedStorageAddress] = useState<
    string | undefined
  >(
    storageAddresses?.length
      ? savedStorageAddress
        ? storageAddresses.find((s) => s === savedStorageAddress)
        : undefined
      : undefined
  )
  const [formState, setFormState] = useState<
    "storage" | "shapes" | "imports" | "terms"
  >(usedStorageAddress ? "shapes" : "storage")
  const [formProgress, setFormProgress] = useState(0)
  const [formProgressTimeout, setFormProgressTimeout] = useState<
    ReturnType<typeof setTimeout> | undefined
  >()
  const [existingShapes, setExistingShapes] = useState(
    Object.assign(
      {},
      ...children(usedStorageAddress ?? "https://example.com").map(
        (prompt) => ({
          [prompt.props.shape.id]: false,
        })
      )
    ) as { [shape: string]: boolean | string }
  )

  useEffect(() => {
    let p = formProgress
    if (formProgressTimeout) {
      clearTimeout(formProgressTimeout)
      setFormProgressTimeout(setTimeout(progress, 20))
    } else {
      setFormProgressTimeout(setTimeout(progress, 20))
    }
    function progress() {
      if (formState === "storage") {
        if (p >= 25) {
          clearTimeout(formProgressTimeout)
        } else {
          ++p
          setFormProgress((p) => p + 1)
        }
      }
      if (formState === "shapes") {
        if (p >= 50) {
          clearTimeout(formProgressTimeout)
        } else {
          ++p
          setFormProgress((p) => p + 1)
        }
      }
      if (formState === "imports") {
        if (p >= 75) {
          clearTimeout(formProgressTimeout)
        } else {
          ++p
          setFormProgress((p) => p + 1)
        }
      }
      if (formState === "terms") {
        if (p >= 100) {
          clearTimeout(formProgressTimeout)
        } else {
          ++p
          setFormProgress((p) => p + 1)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, formProgress])

  const emptyImports = useMemo(() => {
    const values = Object.values(importedData)
    if (values.find((v) => (v && !v.length) || (v && v.length !== 0))) {
      return false
    }
    return true
  }, [importedData])

  useEffect(() => {
    const shapesExist =
      Object.values(existingShapes).filter((s) => !!s).length ===
      Object.values(existingShapes).length

    if (Object.values(existingShapes).find((v) => v === "needed")) {
      onFail()
    } else if (shapesExist && hidden && usedStorageAddress) {
      onFinish(usedStorageAddress)
    }
  }, [existingShapes, hidden, onFinish, onFail, usedStorageAddress])

  return (
    <div className={styles.main}>
      <h2>Data Policy</h2>
      {formState === "storage" && (
        <Formik
          initialValues={{ storage: storageAddresses?.at(0) }}
          onSubmit={(values) => {
            if (values.storage) {
              setUsedStorageAddress(values.storage)
              localStorage.setItem("usedStorageAddress", values.storage)
              setFormState("shapes")
            }
          }}
        >
          {({ handleSubmit, values }) => (
            <form>
              <h4>Which storage location would you like Projektor to use? 🏘</h4>
              <SelectMenu
                name="storage"
                defaultValue={
                  storageAddresses ? storageAddresses[0] : undefined
                }
                options={
                  storageAddresses?.map((s, i) => ({
                    label: `${
                      i === 0 ? "(default) " : ""
                    }${getAbbreviatedStorageName(s)}`,
                    value: s,
                  })) ?? []
                }
              />
              <Button
                color="blue"
                disabled={!values.storage}
                onClick={() => {
                  handleSubmit()
                }}
              >
                Continue
              </Button>
            </form>
          )}
        </Formik>
      )}
      {formState === "shapes" && (
        <div className={styles.shapes}>
          <p>
            To allow Projektor to work properly it must be ensured that the
            required data structures exist on your account.
          </p>
          <PolicyFormContext.Provider
            value={{
              onExist: (shape) => {
                setExistingShapes((existingShapes) => ({
                  ...existingShapes,
                  [shape]: true,
                }))
              },
              onNotExist: (shape) => {
                setExistingShapes((existingShapes) => ({
                  ...existingShapes,
                  [shape]: "needed",
                }))
              },
            }}
          >
            {children(usedStorageAddress as string)}
          </PolicyFormContext.Provider>
          <Button
            color="blue"
            disabled={
              Object.values(existingShapes).filter(
                (s) => typeof s === "boolean" && s === true
              ).length !== Object.values(existingShapes).length
            }
            onClick={() => {
              if (emptyImports) {
                setFormState("terms")
              } else {
                setFormState("imports")
              }
            }}
          >
            Continue
          </Button>
        </div>
      )}
      {formState === "imports" && (
        <form>
          <p>Projektor would like to import some of your data:</p>
          {Object.keys(importedData)
            .map((key) => {
              const data = (importedData as Record<string, string>)[key]
              if (data && data.length)
                return (
                  <div className={styles.import} key={key}>
                    <b>{data.length}</b>
                    <b>in {sentenceCase(key)}</b>
                  </div>
                )
              return undefined
            })
            .filter((e) => Boolean(e))}
          <Button
            color="blue"
            onClick={() => {
              setFormState("terms")
            }}
          >
            Accept
          </Button>
        </form>
      )}
      {formState === "terms" && (
        <form>
          <p>
            By using Projektor, you agree to take complete responsibility for
            any content that is created or distributed by your account. You also
            accept that the Projektor platform may store some of your
            information to validate the data found in your account (not
            implemented yet, but you'll be informed when).
          </p>
          <Button
            color="blue"
            onClick={() => {
              if (usedStorageAddress) onFinish(usedStorageAddress)
            }}
          >
            Accept
          </Button>
        </form>
      )}
      <progress
        className={styles.progress}
        value={formProgress}
        max={100}
      ></progress>
    </div>
  )
}

export default DataPolicyPrompt
