import { useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"

import { PodConnectorContext } from "react-pod-connector"
import { getProfileAndStorageAdresses } from "../DataDiscoveryWrapper"
import { person, PersonShape } from "../generated/shex"
import { Session } from "@inrupt/solid-client-authn-browser"

export const usePersonProfile = (webId: string) => {
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [errorLoadingProfile, setErrorLoadingProfile] = useState<
    string | undefined
  >()
  const [isPrivateProfile, setIsPrivateProfile] = useState<boolean>(false)
  const [currentProfile, setCurrentProfile] = useState<
    PersonShape | undefined
  >()
  const { session } = useContext(PodConnectorContext)
  useEffect(() => {
    getProfileAndStorageAdresses(session as unknown as Session, webId).then(
      ([_storages, profileAddresses]) => {
        if (
          session?.info.isLoggedIn &&
          profileAddresses?.length &&
          webId &&
          !loadingProfile
        ) {
          setLoadingProfile(true)
          person.fetcher._fetch = session.fetch
          person
            .findOne({
              where: { id: webId },
              doc: profileAddresses,
            })
            .then((personProfile) => {
              if (personProfile.data) {
                setCurrentProfile(personProfile.data)
              } else if (personProfile.errors) {
                console.debug(personProfile.errors, profileAddresses)
                toast("Couldn't read profile of " + webId, {
                  type: "error",
                  hideProgressBar: true,
                })
                setErrorLoadingProfile(personProfile.errors?.join("\n"))
              }
              setLoadingProfile(false)
            })
            .catch((e) => {
              if (e.status === 403) {
                setIsPrivateProfile(true)
              }
              setErrorLoadingProfile(e)
              setLoadingProfile(false)
            })
        }
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webId])

  return {
    privateProfile: isPrivateProfile,
    loading: loadingProfile,
    data: currentProfile,
    error: errorLoadingProfile,
  }
}
