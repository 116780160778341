import React from "react";
import Page from "../Page";

function ErrorPage() {
  return (
    <Page title="An error has occured...">
      <div>
        Oops... This page doesn't exist. Please return to the main page.
      </div>
    </Page>
  );
}

export default ErrorPage;
