import { getProfileAll } from "@inrupt/solid-client"
import { FOAF } from "@inrupt/vocab-common-rdf"
import { NamedNode } from "rdflib"
import { useCallback, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import { v4 as uuid } from "uuid"

import { DataDiscoveryContext } from "../context/DataDiscoveryContext"

import { PodConnectorContext } from "react-pod-connector"
import {
  addressBookEntry,
  AddressBookEntryShapeType,
  addressBookIndividualEntry,
  AddressBookIndividualEntryShapeType,
  nameIndexEntry,
} from "../generated/shex"
import { getUsernameFromWebId } from "../helpers/username"
import { getNewIndexEntryPathFor } from "./post"

export const useCreateContact = () => {
  const [creatingContact, setCreatingContact] = useState(false)
  const {
    storageAddresses,
    importedData: { addressBook },
    setImportedData,
  } = useContext(DataDiscoveryContext)
  const { session } = useContext(PodConnectorContext)

  const createContact = useCallback(
    async (webId: string) => {
      if (addressBook && storageAddresses?.length && session) {
        setCreatingContact(true)
        addressBookIndividualEntry.fetcher._fetch = session.fetch
        addressBookEntry.fetcher._fetch = session.fetch
        nameIndexEntry.fetcher._fetch = session.fetch
        const id = getNewIndexEntryPathFor(
          "Person",
          storageAddresses[0],
          addressBook.id
        )
        try {
          const profile = (
            (
              await getProfileAll(webId as string, {
                fetch: session.fetch,
              })
            ).webIdProfile.graphs.default as Record<string, any>
          )[webId as string]
          const name = profile.predicates[FOAF.name] ?? "undefined"
          const newWebId = await addressBookEntry
            .create({
              data: {
                id: id.replace("#this", `#${uuid()}`),
                type: AddressBookEntryShapeType.WebId,
                value: new URL(webId),
              },
              doc: new NamedNode(id).doc().uri,
            })
            .then(({ data }) => {
              return data
            })
          if (newWebId) {
            const newContact = await addressBookIndividualEntry
              .create({
                data: {
                  id,
                  type: AddressBookIndividualEntryShapeType.Individual,
                  url: new URL(newWebId?.id),
                },
                doc: new NamedNode(id).doc().uri,
              })
              .then(({ data }) => {
                return data
              })
            if (newContact) {
              await nameIndexEntry
                .create({
                  data: { id, inAddressBook: new URL(addressBook.id), name },
                  doc: addressBook.nameEmailIndex,
                })
                .then(() => {
                  toast(
                    `Successfully added ${getUsernameFromWebId(
                      webId
                    )} to your contacts.`,
                    { type: "success" }
                  )
                  setCreatingContact(false)
                })
              if (setImportedData)
                setImportedData((imports) => ({
                  ...imports,
                  contacts: [...(imports.contacts ?? []), newContact],
                }))
            }
          }
        } catch {
          setCreatingContact(false)
        }
      }
    },
    [addressBook, setImportedData, session, storageAddresses]
  )

  return { loading: creatingContact, createContact }
}

interface DisplayContact {
  name?: string
  webId: string
}

export const useFetchContacts = () => {
  const [contacts, setContacts] = useState<DisplayContact[]>([])
  const {
    importedData: { contacts: foundContacts },
  } = useContext(DataDiscoveryContext)

  useEffect(() => {
    setContacts(
      foundContacts?.map((c) => {
        return { webId: c.url.value }
      }) ?? []
    )
  }, [foundContacts])

  return { contacts }
}
