import { useContext, useEffect } from "react"
import { useNavigate } from "react-router"

import Button from "../../components/Button/Button"
import Post from "../../components/Post/Post"
import Page from "../Page"

import { PodConnectorContext } from "react-pod-connector"
import EmptyView from "../../components/EmptyView/EmptyView"
import { DataDiscoveryContext } from "../../context/DataDiscoveryContext"
import { useFetchPosts } from "../../hooks/post"
import styles from "./HomePage.module.scss"

function HomeContactSidebar({ name }: { name?: string }) {
  const navigate = useNavigate()
  return (
    <aside className={styles.contactSidebar}>
      {name && <h3>Hello, {name}.</h3>}
      <p>
        To see more on your homescreen, it is recommend that you add some of
        your contacts.
      </p>
      <Button
        color="green"
        onClick={() => {
          navigate("/contacts")
        }}
      >
        Add Contacts
      </Button>
    </aside>
  )
}

function HomePage() {
  const { session } = useContext(PodConnectorContext)
  const { webId } = session?.info ?? {}
  const { loading, myPosts, fetchMyPosts } = useFetchPosts()
  const { importedData } = useContext(DataDiscoveryContext)

  useEffect(() => {
    if (!loading) fetchMyPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedData.posts])

  useEffect(() => {}, [importedData.posts])

  return (
    <Page
      maxWidth
      title="The Projektor"
      rightSidebar={
        <HomeContactSidebar
          name={webId?.substring(webId.lastIndexOf("/") + 1, webId.length)}
        />
      }
      loading={loading}
    >
      <div className={styles.main}>
        {myPosts.length ? (
          myPosts.map(({ id, title, caption, media }) => {
            return (
              <Post
                key={id}
                fullSize
                title={title}
                caption={caption}
                media={media}
              />
            )
          })
        ) : (
          <EmptyView message="Looks like there are no posts yet..." />
        )}
      </div>
    </Page>
  )
}

export default HomePage
